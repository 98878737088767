@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
.paper html{font-family:sans-serif;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%}
.paper body{margin:0}
.paper article, .paper aside, .paper details, .paper figcaption, .paper figure, .paper footer, .paper header, .paper hgroup, .paper main, .paper menu, .paper nav, .paper section, .paper summary{display:block}
.paper audio, .paper canvas, .paper progress, .paper video{display:inline-block;vertical-align:baseline}
.paper audio:not([controls]){display:none;height:0}
.paper [hidden], .paper template{display:none}
.paper a{background-color:transparent}
.paper a:active, .paper a:hover{outline:0}
.paper abbr[title]{border-bottom:1px dotted}
.paper b, .paper strong{font-weight:bold}
.paper dfn{font-style:italic}
.paper h1{font-size:2em;margin:0.67em 0}
.paper mark{background:#ff0;color:#000}
.paper small{font-size:80%}
.paper sub, .paper sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}
.paper sup{top:-0.5em}
.paper sub{bottom:-0.25em}
.paper img{border:0}
.paper svg:not(:root){overflow:hidden}
.paper figure{margin:1em 40px}
.paper hr{-webkit-box-sizing:content-box;-moz-box-sizing:content-box;box-sizing:content-box;height:0}
.paper pre{overflow:auto}
.paper code, .paper kbd, .paper pre, .paper samp{font-family:monospace, monospace;font-size:1em}
.paper button, .paper input, .paper optgroup, .paper select, .paper textarea{color:inherit;font:inherit;margin:0}
.paper button{overflow:visible}
.paper button, .paper select{text-transform:none}
.paper button, .paper html input[type="button"], .paper input[type="reset"], .paper input[type="submit"]{-webkit-appearance:button;cursor:pointer}
.paper button[disabled], .paper html input[disabled]{cursor:default}
.paper button::-moz-focus-inner, .paper input::-moz-focus-inner{border:0;padding:0}
.paper input{line-height:normal}
.paper input[type="checkbox"], .paper input[type="radio"]{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;padding:0}
.paper input[type="number"]::-webkit-inner-spin-button, .paper input[type="number"]::-webkit-outer-spin-button{height:auto}
.paper input[type="search"]{-webkit-appearance:textfield;-webkit-box-sizing:content-box;-moz-box-sizing:content-box;box-sizing:content-box}
.paper input[type="search"]::-webkit-search-cancel-button, .paper input[type="search"]::-webkit-search-decoration{-webkit-appearance:none}
.paper fieldset{border:1px solid #c0c0c0;margin:0 2px;padding:0.35em 0.625em 0.75em}
.paper legend{border:0;padding:0}
.paper textarea{overflow:auto}
.paper optgroup{font-weight:bold}
.paper table{border-collapse:collapse;border-spacing:0}
.paper td, .paper th{padding:0}
@media print{
.paper *, .paper *:before, .paper *:after{background:transparent !important;color:#000 !important;-webkit-box-shadow:none !important;box-shadow:none !important;text-shadow:none !important}
.paper a, .paper a:visited{text-decoration:underline}
.paper a[href]:after{content:" (" attr(href) ")"}
.paper abbr[title]:after{content:" (" attr(title) ")"}
.paper a[href^="#"]:after, .paper a[href^="javascript:"]:after{content:""}
.paper pre, .paper blockquote{border:1px solid #999;page-break-inside:avoid}
.paper thead{display:table-header-group}
.paper tr, .paper img{page-break-inside:avoid}
.paper img{max-width:100% !important}
.paper p, .paper h2, .paper h3{orphans:3;widows:3}
.paper h2, .paper h3{page-break-after:avoid}
.paper .navbar{display:none}
.paper .btn>.caret, .paper .dropup>.btn>.caret{border-top-color:#000 !important}
.paper .label{border:1px solid #000}
.paper .table{border-collapse:collapse !important}
.paper .table td, .paper .table th{background-color:#fff !important}
.paper .table-bordered th, .paper .table-bordered td{border:1px solid #ddd !important}
}
@font-face{font-family:'Glyphicons Halflings';src:url('../fonts/glyphicons-halflings-regular.eot');src:url('../fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),url('../fonts/glyphicons-halflings-regular.woff2') format('woff2'),url('../fonts/glyphicons-halflings-regular.woff') format('woff'),url('../fonts/glyphicons-halflings-regular.ttf') format('truetype'),url('../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg')}
.paper .glyphicon{position:relative;top:1px;display:inline-block;font-family:'Glyphicons Halflings';font-style:normal;font-weight:normal;line-height:1;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}
.paper .glyphicon-asterisk:before{content:"\002a"}
.paper .glyphicon-plus:before{content:"\002b"}
.paper .glyphicon-euro:before, .paper .glyphicon-eur:before{content:"\20ac"}
.paper .glyphicon-minus:before{content:"\2212"}
.paper .glyphicon-cloud:before{content:"\2601"}
.paper .glyphicon-envelope:before{content:"\2709"}
.paper .glyphicon-pencil:before{content:"\270f"}
.paper .glyphicon-glass:before{content:"\e001"}
.paper .glyphicon-music:before{content:"\e002"}
.paper .glyphicon-search:before{content:"\e003"}
.paper .glyphicon-heart:before{content:"\e005"}
.paper .glyphicon-star:before{content:"\e006"}
.paper .glyphicon-star-empty:before{content:"\e007"}
.paper .glyphicon-user:before{content:"\e008"}
.paper .glyphicon-film:before{content:"\e009"}
.paper .glyphicon-th-large:before{content:"\e010"}
.paper .glyphicon-th:before{content:"\e011"}
.paper .glyphicon-th-list:before{content:"\e012"}
.paper .glyphicon-ok:before{content:"\e013"}
.paper .glyphicon-remove:before{content:"\e014"}
.paper .glyphicon-zoom-in:before{content:"\e015"}
.paper .glyphicon-zoom-out:before{content:"\e016"}
.paper .glyphicon-off:before{content:"\e017"}
.paper .glyphicon-signal:before{content:"\e018"}
.paper .glyphicon-cog:before{content:"\e019"}
.paper .glyphicon-trash:before{content:"\e020"}
.paper .glyphicon-home:before{content:"\e021"}
.paper .glyphicon-file:before{content:"\e022"}
.paper .glyphicon-time:before{content:"\e023"}
.paper .glyphicon-road:before{content:"\e024"}
.paper .glyphicon-download-alt:before{content:"\e025"}
.paper .glyphicon-download:before{content:"\e026"}
.paper .glyphicon-upload:before{content:"\e027"}
.paper .glyphicon-inbox:before{content:"\e028"}
.paper .glyphicon-play-circle:before{content:"\e029"}
.paper .glyphicon-repeat:before{content:"\e030"}
.paper .glyphicon-refresh:before{content:"\e031"}
.paper .glyphicon-list-alt:before{content:"\e032"}
.paper .glyphicon-lock:before{content:"\e033"}
.paper .glyphicon-flag:before{content:"\e034"}
.paper .glyphicon-headphones:before{content:"\e035"}
.paper .glyphicon-volume-off:before{content:"\e036"}
.paper .glyphicon-volume-down:before{content:"\e037"}
.paper .glyphicon-volume-up:before{content:"\e038"}
.paper .glyphicon-qrcode:before{content:"\e039"}
.paper .glyphicon-barcode:before{content:"\e040"}
.paper .glyphicon-tag:before{content:"\e041"}
.paper .glyphicon-tags:before{content:"\e042"}
.paper .glyphicon-book:before{content:"\e043"}
.paper .glyphicon-bookmark:before{content:"\e044"}
.paper .glyphicon-print:before{content:"\e045"}
.paper .glyphicon-camera:before{content:"\e046"}
.paper .glyphicon-font:before{content:"\e047"}
.paper .glyphicon-bold:before{content:"\e048"}
.paper .glyphicon-italic:before{content:"\e049"}
.paper .glyphicon-text-height:before{content:"\e050"}
.paper .glyphicon-text-width:before{content:"\e051"}
.paper .glyphicon-align-left:before{content:"\e052"}
.paper .glyphicon-align-center:before{content:"\e053"}
.paper .glyphicon-align-right:before{content:"\e054"}
.paper .glyphicon-align-justify:before{content:"\e055"}
.paper .glyphicon-list:before{content:"\e056"}
.paper .glyphicon-indent-left:before{content:"\e057"}
.paper .glyphicon-indent-right:before{content:"\e058"}
.paper .glyphicon-facetime-video:before{content:"\e059"}
.paper .glyphicon-picture:before{content:"\e060"}
.paper .glyphicon-map-marker:before{content:"\e062"}
.paper .glyphicon-adjust:before{content:"\e063"}
.paper .glyphicon-tint:before{content:"\e064"}
.paper .glyphicon-edit:before{content:"\e065"}
.paper .glyphicon-share:before{content:"\e066"}
.paper .glyphicon-check:before{content:"\e067"}
.paper .glyphicon-move:before{content:"\e068"}
.paper .glyphicon-step-backward:before{content:"\e069"}
.paper .glyphicon-fast-backward:before{content:"\e070"}
.paper .glyphicon-backward:before{content:"\e071"}
.paper .glyphicon-play:before{content:"\e072"}
.paper .glyphicon-pause:before{content:"\e073"}
.paper .glyphicon-stop:before{content:"\e074"}
.paper .glyphicon-forward:before{content:"\e075"}
.paper .glyphicon-fast-forward:before{content:"\e076"}
.paper .glyphicon-step-forward:before{content:"\e077"}
.paper .glyphicon-eject:before{content:"\e078"}
.paper .glyphicon-chevron-left:before{content:"\e079"}
.paper .glyphicon-chevron-right:before{content:"\e080"}
.paper .glyphicon-plus-sign:before{content:"\e081"}
.paper .glyphicon-minus-sign:before{content:"\e082"}
.paper .glyphicon-remove-sign:before{content:"\e083"}
.paper .glyphicon-ok-sign:before{content:"\e084"}
.paper .glyphicon-question-sign:before{content:"\e085"}
.paper .glyphicon-info-sign:before{content:"\e086"}
.paper .glyphicon-screenshot:before{content:"\e087"}
.paper .glyphicon-remove-circle:before{content:"\e088"}
.paper .glyphicon-ok-circle:before{content:"\e089"}
.paper .glyphicon-ban-circle:before{content:"\e090"}
.paper .glyphicon-arrow-left:before{content:"\e091"}
.paper .glyphicon-arrow-right:before{content:"\e092"}
.paper .glyphicon-arrow-up:before{content:"\e093"}
.paper .glyphicon-arrow-down:before{content:"\e094"}
.paper .glyphicon-share-alt:before{content:"\e095"}
.paper .glyphicon-resize-full:before{content:"\e096"}
.paper .glyphicon-resize-small:before{content:"\e097"}
.paper .glyphicon-exclamation-sign:before{content:"\e101"}
.paper .glyphicon-gift:before{content:"\e102"}
.paper .glyphicon-leaf:before{content:"\e103"}
.paper .glyphicon-fire:before{content:"\e104"}
.paper .glyphicon-eye-open:before{content:"\e105"}
.paper .glyphicon-eye-close:before{content:"\e106"}
.paper .glyphicon-warning-sign:before{content:"\e107"}
.paper .glyphicon-plane:before{content:"\e108"}
.paper .glyphicon-calendar:before{content:"\e109"}
.paper .glyphicon-random:before{content:"\e110"}
.paper .glyphicon-comment:before{content:"\e111"}
.paper .glyphicon-magnet:before{content:"\e112"}
.paper .glyphicon-chevron-up:before{content:"\e113"}
.paper .glyphicon-chevron-down:before{content:"\e114"}
.paper .glyphicon-retweet:before{content:"\e115"}
.paper .glyphicon-shopping-cart:before{content:"\e116"}
.paper .glyphicon-folder-close:before{content:"\e117"}
.paper .glyphicon-folder-open:before{content:"\e118"}
.paper .glyphicon-resize-vertical:before{content:"\e119"}
.paper .glyphicon-resize-horizontal:before{content:"\e120"}
.paper .glyphicon-hdd:before{content:"\e121"}
.paper .glyphicon-bullhorn:before{content:"\e122"}
.paper .glyphicon-bell:before{content:"\e123"}
.paper .glyphicon-certificate:before{content:"\e124"}
.paper .glyphicon-thumbs-up:before{content:"\e125"}
.paper .glyphicon-thumbs-down:before{content:"\e126"}
.paper .glyphicon-hand-right:before{content:"\e127"}
.paper .glyphicon-hand-left:before{content:"\e128"}
.paper .glyphicon-hand-up:before{content:"\e129"}
.paper .glyphicon-hand-down:before{content:"\e130"}
.paper .glyphicon-circle-arrow-right:before{content:"\e131"}
.paper .glyphicon-circle-arrow-left:before{content:"\e132"}
.paper .glyphicon-circle-arrow-up:before{content:"\e133"}
.paper .glyphicon-circle-arrow-down:before{content:"\e134"}
.paper .glyphicon-globe:before{content:"\e135"}
.paper .glyphicon-wrench:before{content:"\e136"}
.paper .glyphicon-tasks:before{content:"\e137"}
.paper .glyphicon-filter:before{content:"\e138"}
.paper .glyphicon-briefcase:before{content:"\e139"}
.paper .glyphicon-fullscreen:before{content:"\e140"}
.paper .glyphicon-dashboard:before{content:"\e141"}
.paper .glyphicon-paperclip:before{content:"\e142"}
.paper .glyphicon-heart-empty:before{content:"\e143"}
.paper .glyphicon-link:before{content:"\e144"}
.paper .glyphicon-phone:before{content:"\e145"}
.paper .glyphicon-pushpin:before{content:"\e146"}
.paper .glyphicon-usd:before{content:"\e148"}
.paper .glyphicon-gbp:before{content:"\e149"}
.paper .glyphicon-sort:before{content:"\e150"}
.paper .glyphicon-sort-by-alphabet:before{content:"\e151"}
.paper .glyphicon-sort-by-alphabet-alt:before{content:"\e152"}
.paper .glyphicon-sort-by-order:before{content:"\e153"}
.paper .glyphicon-sort-by-order-alt:before{content:"\e154"}
.paper .glyphicon-sort-by-attributes:before{content:"\e155"}
.paper .glyphicon-sort-by-attributes-alt:before{content:"\e156"}
.paper .glyphicon-unchecked:before{content:"\e157"}
.paper .glyphicon-expand:before{content:"\e158"}
.paper .glyphicon-collapse-down:before{content:"\e159"}
.paper .glyphicon-collapse-up:before{content:"\e160"}
.paper .glyphicon-log-in:before{content:"\e161"}
.paper .glyphicon-flash:before{content:"\e162"}
.paper .glyphicon-log-out:before{content:"\e163"}
.paper .glyphicon-new-window:before{content:"\e164"}
.paper .glyphicon-record:before{content:"\e165"}
.paper .glyphicon-save:before{content:"\e166"}
.paper .glyphicon-open:before{content:"\e167"}
.paper .glyphicon-saved:before{content:"\e168"}
.paper .glyphicon-import:before{content:"\e169"}
.paper .glyphicon-export:before{content:"\e170"}
.paper .glyphicon-send:before{content:"\e171"}
.paper .glyphicon-floppy-disk:before{content:"\e172"}
.paper .glyphicon-floppy-saved:before{content:"\e173"}
.paper .glyphicon-floppy-remove:before{content:"\e174"}
.paper .glyphicon-floppy-save:before{content:"\e175"}
.paper .glyphicon-floppy-open:before{content:"\e176"}
.paper .glyphicon-credit-card:before{content:"\e177"}
.paper .glyphicon-transfer:before{content:"\e178"}
.paper .glyphicon-cutlery:before{content:"\e179"}
.paper .glyphicon-header:before{content:"\e180"}
.paper .glyphicon-compressed:before{content:"\e181"}
.paper .glyphicon-earphone:before{content:"\e182"}
.paper .glyphicon-phone-alt:before{content:"\e183"}
.paper .glyphicon-tower:before{content:"\e184"}
.paper .glyphicon-stats:before{content:"\e185"}
.paper .glyphicon-sd-video:before{content:"\e186"}
.paper .glyphicon-hd-video:before{content:"\e187"}
.paper .glyphicon-subtitles:before{content:"\e188"}
.paper .glyphicon-sound-stereo:before{content:"\e189"}
.paper .glyphicon-sound-dolby:before{content:"\e190"}
.paper .glyphicon-sound-5-1:before{content:"\e191"}
.paper .glyphicon-sound-6-1:before{content:"\e192"}
.paper .glyphicon-sound-7-1:before{content:"\e193"}
.paper .glyphicon-copyright-mark:before{content:"\e194"}
.paper .glyphicon-registration-mark:before{content:"\e195"}
.paper .glyphicon-cloud-download:before{content:"\e197"}
.paper .glyphicon-cloud-upload:before{content:"\e198"}
.paper .glyphicon-tree-conifer:before{content:"\e199"}
.paper .glyphicon-tree-deciduous:before{content:"\e200"}
.paper .glyphicon-cd:before{content:"\e201"}
.paper .glyphicon-save-file:before{content:"\e202"}
.paper .glyphicon-open-file:before{content:"\e203"}
.paper .glyphicon-level-up:before{content:"\e204"}
.paper .glyphicon-copy:before{content:"\e205"}
.paper .glyphicon-paste:before{content:"\e206"}
.paper .glyphicon-alert:before{content:"\e209"}
.paper .glyphicon-equalizer:before{content:"\e210"}
.paper .glyphicon-king:before{content:"\e211"}
.paper .glyphicon-queen:before{content:"\e212"}
.paper .glyphicon-pawn:before{content:"\e213"}
.paper .glyphicon-bishop:before{content:"\e214"}
.paper .glyphicon-knight:before{content:"\e215"}
.paper .glyphicon-baby-formula:before{content:"\e216"}
.paper .glyphicon-tent:before{content:"\26fa"}
.paper .glyphicon-blackboard:before{content:"\e218"}
.paper .glyphicon-bed:before{content:"\e219"}
.paper .glyphicon-apple:before{content:"\f8ff"}
.paper .glyphicon-erase:before{content:"\e221"}
.paper .glyphicon-hourglass:before{content:"\231b"}
.paper .glyphicon-lamp:before{content:"\e223"}
.paper .glyphicon-duplicate:before{content:"\e224"}
.paper .glyphicon-piggy-bank:before{content:"\e225"}
.paper .glyphicon-scissors:before{content:"\e226"}
.paper .glyphicon-bitcoin:before{content:"\e227"}
.paper .glyphicon-btc:before{content:"\e227"}
.paper .glyphicon-xbt:before{content:"\e227"}
.paper .glyphicon-yen:before{content:"\00a5"}
.paper .glyphicon-jpy:before{content:"\00a5"}
.paper .glyphicon-ruble:before{content:"\20bd"}
.paper .glyphicon-rub:before{content:"\20bd"}
.paper .glyphicon-scale:before{content:"\e230"}
.paper .glyphicon-ice-lolly:before{content:"\e231"}
.paper .glyphicon-ice-lolly-tasted:before{content:"\e232"}
.paper .glyphicon-education:before{content:"\e233"}
.paper .glyphicon-option-horizontal:before{content:"\e234"}
.paper .glyphicon-option-vertical:before{content:"\e235"}
.paper .glyphicon-menu-hamburger:before{content:"\e236"}
.paper .glyphicon-modal-window:before{content:"\e237"}
.paper .glyphicon-oil:before{content:"\e238"}
.paper .glyphicon-grain:before{content:"\e239"}
.paper .glyphicon-sunglasses:before{content:"\e240"}
.paper .glyphicon-text-size:before{content:"\e241"}
.paper .glyphicon-text-color:before{content:"\e242"}
.paper .glyphicon-text-background:before{content:"\e243"}
.paper .glyphicon-object-align-top:before{content:"\e244"}
.paper .glyphicon-object-align-bottom:before{content:"\e245"}
.paper .glyphicon-object-align-horizontal:before{content:"\e246"}
.paper .glyphicon-object-align-left:before{content:"\e247"}
.paper .glyphicon-object-align-vertical:before{content:"\e248"}
.paper .glyphicon-object-align-right:before{content:"\e249"}
.paper .glyphicon-triangle-right:before{content:"\e250"}
.paper .glyphicon-triangle-left:before{content:"\e251"}
.paper .glyphicon-triangle-bottom:before{content:"\e252"}
.paper .glyphicon-triangle-top:before{content:"\e253"}
.paper .glyphicon-console:before{content:"\e254"}
.paper .glyphicon-superscript:before{content:"\e255"}
.paper .glyphicon-subscript:before{content:"\e256"}
.paper .glyphicon-menu-left:before{content:"\e257"}
.paper .glyphicon-menu-right:before{content:"\e258"}
.paper .glyphicon-menu-down:before{content:"\e259"}
.paper .glyphicon-menu-up:before{content:"\e260"}
.paper *{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
.paper *:before, .paper *:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
.paper html{font-size:10px;-webkit-tap-highlight-color:rgba(0,0,0,0)}
.paper body{font-family:"Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;font-size:13px;line-height:1.846;color:#666666;background-color:#ffffff}
.paper input, .paper button, .paper select, .paper textarea{font-family:inherit;font-size:inherit;line-height:inherit}
.paper a{color:#2196f3;text-decoration:none}
.paper a:hover, .paper a:focus{color:#0a6ebd;text-decoration:underline}
.paper a:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px}
.paper figure{margin:0}
.paper img{vertical-align:middle}
.paper .img-responsive, .paper .thumbnail>img, .paper .thumbnail a>img, .paper .carousel-inner>.item>img, .paper .carousel-inner>.item>a>img{display:block;max-width:100%;height:auto}
.paper .img-rounded{border-radius:3px}
.paper .img-thumbnail{padding:4px;line-height:1.846;background-color:#ffffff;border:1px solid #dddddd;border-radius:3px;-webkit-transition:all .2s ease-in-out;-o-transition:all .2s ease-in-out;transition:all .2s ease-in-out;display:inline-block;max-width:100%;height:auto}
.paper .img-circle{border-radius:50%}
.paper hr{margin-top:23px;margin-bottom:23px;border:0;border-top:1px solid #eeeeee}
.paper .sr-only{position:absolute;width:1px;height:1px;margin:-1px;padding:0;overflow:hidden;clip:rect(0, 0, 0, 0);border:0}
.paper .sr-only-focusable:active, .paper .sr-only-focusable:focus{position:static;width:auto;height:auto;margin:0;overflow:visible;clip:auto}
.paper [role="button"]{cursor:pointer}
.paper h1, .paper h2, .paper h3, .paper h4, .paper h5, .paper h6, .paper .h1, .paper .h2, .paper .h3, .paper .h4, .paper .h5, .paper .h6{font-family:inherit;font-weight:400;line-height:1.1;color:#444444}
.paper h1 small, .paper h2 small, .paper h3 small, .paper h4 small, .paper h5 small, .paper h6 small, .paper .h1 small, .paper .h2 small, .paper .h3 small, .paper .h4 small, .paper .h5 small, .paper .h6 small, .paper h1 .small, .paper h2 .small, .paper h3 .small, .paper h4 .small, .paper h5 .small, .paper h6 .small, .paper .h1 .small, .paper .h2 .small, .paper .h3 .small, .paper .h4 .small, .paper .h5 .small, .paper .h6 .small{font-weight:normal;line-height:1;color:#bbbbbb}
.paper h1, .paper .h1, .paper h2, .paper .h2, .paper h3, .paper .h3{margin-top:23px;margin-bottom:11.5px}
.paper h1 small, .paper .h1 small, .paper h2 small, .paper .h2 small, .paper h3 small, .paper .h3 small, .paper h1 .small, .paper .h1 .small, .paper h2 .small, .paper .h2 .small, .paper h3 .small, .paper .h3 .small{font-size:65%}
.paper h4, .paper .h4, .paper h5, .paper .h5, .paper h6, .paper .h6{margin-top:11.5px;margin-bottom:11.5px}
.paper h4 small, .paper .h4 small, .paper h5 small, .paper .h5 small, .paper h6 small, .paper .h6 small, .paper h4 .small, .paper .h4 .small, .paper h5 .small, .paper .h5 .small, .paper h6 .small, .paper .h6 .small{font-size:75%}
.paper h1, .paper .h1{font-size:56px}
.paper h2, .paper .h2{font-size:45px}
.paper h3, .paper .h3{font-size:34px}
.paper h4, .paper .h4{font-size:24px}
.paper h5, .paper .h5{font-size:20px}
.paper h6, .paper .h6{font-size:14px}
.paper p{margin:0 0 11.5px}
.paper .lead{margin-bottom:23px;font-size:14px;font-weight:300;line-height:1.4}
@media (min-width:768px){
.paper .lead{font-size:19.5px}
}
.paper small, .paper .small{font-size:92%}
.paper mark, .paper .mark{background-color:#ffe0b2;padding:.2em}
.paper .text-left{text-align:left}
.paper .text-right{text-align:right}
.paper .text-center{text-align:center}
.paper .text-justify{text-align:justify}
.paper .text-nowrap{white-space:nowrap}
.paper .text-lowercase{text-transform:lowercase}
.paper .text-uppercase{text-transform:uppercase}
.paper .text-capitalize{text-transform:capitalize}
.paper .text-muted{color:#bbbbbb}
.paper .text-primary{color:#2196f3}
.paper a.text-primary:hover, .paper a.text-primary:focus{color:#0c7cd5}
.paper .text-success{color:#4caf50}
.paper a.text-success:hover, .paper a.text-success:focus{color:#3d8b40}
.paper .text-info{color:#9c27b0}
.paper a.text-info:hover, .paper a.text-info:focus{color:#771e86}
.paper .text-warning{color:#ff9800}
.paper a.text-warning:hover, .paper a.text-warning:focus{color:#cc7a00}
.paper .text-danger{color:#e51c23}
.paper a.text-danger:hover, .paper a.text-danger:focus{color:#b9151b}
.paper .bg-primary{color:#fff;background-color:#2196f3}
.paper a.bg-primary:hover, .paper a.bg-primary:focus{background-color:#0c7cd5}
.paper .bg-success{background-color:#dff0d8}
.paper a.bg-success:hover, .paper a.bg-success:focus{background-color:#c1e2b3}
.paper .bg-info{background-color:#e1bee7}
.paper a.bg-info:hover, .paper a.bg-info:focus{background-color:#d099d9}
.paper .bg-warning{background-color:#ffe0b2}
.paper a.bg-warning:hover, .paper a.bg-warning:focus{background-color:#ffcb7f}
.paper .bg-danger{background-color:#f9bdbb}
.paper a.bg-danger:hover, .paper a.bg-danger:focus{background-color:#f5908c}
.paper .page-header{padding-bottom:10.5px;margin:46px 0 23px;border-bottom:1px solid #eeeeee}
.paper ul, .paper ol{margin-top:0;margin-bottom:11.5px}
.paper ul ul, .paper ol ul, .paper ul ol, .paper ol ol{margin-bottom:0}
.paper .list-unstyled{padding-left:0;list-style:none}
.paper .list-inline{padding-left:0;list-style:none;margin-left:-5px}
.paper .list-inline>li{display:inline-block;padding-left:5px;padding-right:5px}
.paper dl{margin-top:0;margin-bottom:23px}
.paper dt, .paper dd{line-height:1.846}
.paper dt{font-weight:bold}
.paper dd{margin-left:0}
@media (min-width:768px){
.paper .dl-horizontal dt{float:left;width:160px;clear:left;text-align:right;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}
.paper .dl-horizontal dd{margin-left:180px}
}
.paper abbr[title], .paper abbr[data-original-title]{cursor:help;border-bottom:1px dotted #bbbbbb}
.paper .initialism{font-size:90%;text-transform:uppercase}
.paper blockquote{padding:11.5px 23px;margin:0 0 23px;font-size:16.25px;border-left:5px solid #eeeeee}
.paper blockquote p:last-child, .paper blockquote ul:last-child, .paper blockquote ol:last-child{margin-bottom:0}
.paper blockquote footer, .paper blockquote small, .paper blockquote .small{display:block;font-size:80%;line-height:1.846;color:#bbbbbb}
.paper blockquote footer:before, .paper blockquote small:before, .paper blockquote .small:before{content:'\2014 \00A0'}
.paper .blockquote-reverse, .paper blockquote.pull-right{padding-right:15px;padding-left:0;border-right:5px solid #eeeeee;border-left:0;text-align:right}
.paper .blockquote-reverse footer:before, .paper blockquote.pull-right footer:before, .paper .blockquote-reverse small:before, .paper blockquote.pull-right small:before, .paper .blockquote-reverse .small:before, .paper blockquote.pull-right .small:before{content:''}
.paper .blockquote-reverse footer:after, .paper blockquote.pull-right footer:after, .paper .blockquote-reverse small:after, .paper blockquote.pull-right small:after, .paper .blockquote-reverse .small:after, .paper blockquote.pull-right .small:after{content:'\00A0 \2014'}
.paper address{margin-bottom:23px;font-style:normal;line-height:1.846}
.paper code, .paper kbd, .paper pre, .paper samp{font-family:Menlo,Monaco,Consolas,"Courier New",monospace}
.paper code{padding:2px 4px;font-size:90%;color:#c7254e;background-color:#f9f2f4;border-radius:3px}
.paper kbd{padding:2px 4px;font-size:90%;color:#ffffff;background-color:#333333;border-radius:3px;-webkit-box-shadow:inset 0 -1px 0 rgba(0,0,0,0.25);box-shadow:inset 0 -1px 0 rgba(0,0,0,0.25)}
.paper kbd kbd{padding:0;font-size:100%;font-weight:bold;-webkit-box-shadow:none;box-shadow:none}
.paper pre{display:block;padding:11px;margin:0 0 11.5px;font-size:12px;line-height:1.846;word-break:break-all;word-wrap:break-word;color:#212121;background-color:#f5f5f5;border:1px solid #cccccc;border-radius:3px}
.paper pre code{padding:0;font-size:inherit;color:inherit;white-space:pre-wrap;background-color:transparent;border-radius:0}
.paper .pre-scrollable{max-height:340px;overflow-y:scroll}
.paper .container{margin-right:auto;margin-left:auto;padding-left:15px;padding-right:15px}
@media (min-width:768px){
.paper .container{width:750px}
}
@media (min-width:992px){
.paper .container{width:970px}
}
@media (min-width:1200px){
.paper .container{width:1170px}
}
.paper .container-fluid{margin-right:auto;margin-left:auto;padding-left:15px;padding-right:15px}
.paper .row{margin-left:-15px;margin-right:-15px}
.paper .col-xs-1, .paper .col-sm-1, .paper .col-md-1, .paper .col-lg-1, .paper .col-xs-2, .paper .col-sm-2, .paper .col-md-2, .paper .col-lg-2, .paper .col-xs-3, .paper .col-sm-3, .paper .col-md-3, .paper .col-lg-3, .paper .col-xs-4, .paper .col-sm-4, .paper .col-md-4, .paper .col-lg-4, .paper .col-xs-5, .paper .col-sm-5, .paper .col-md-5, .paper .col-lg-5, .paper .col-xs-6, .paper .col-sm-6, .paper .col-md-6, .paper .col-lg-6, .paper .col-xs-7, .paper .col-sm-7, .paper .col-md-7, .paper .col-lg-7, .paper .col-xs-8, .paper .col-sm-8, .paper .col-md-8, .paper .col-lg-8, .paper .col-xs-9, .paper .col-sm-9, .paper .col-md-9, .paper .col-lg-9, .paper .col-xs-10, .paper .col-sm-10, .paper .col-md-10, .paper .col-lg-10, .paper .col-xs-11, .paper .col-sm-11, .paper .col-md-11, .paper .col-lg-11, .paper .col-xs-12, .paper .col-sm-12, .paper .col-md-12, .paper .col-lg-12{position:relative;min-height:1px;padding-left:15px;padding-right:15px}
.paper .col-xs-1, .paper .col-xs-2, .paper .col-xs-3, .paper .col-xs-4, .paper .col-xs-5, .paper .col-xs-6, .paper .col-xs-7, .paper .col-xs-8, .paper .col-xs-9, .paper .col-xs-10, .paper .col-xs-11, .paper .col-xs-12{float:left}
.paper .col-xs-12{width:100%}
.paper .col-xs-11{width:91.66666667%}
.paper .col-xs-10{width:83.33333333%}
.paper .col-xs-9{width:75%}
.paper .col-xs-8{width:66.66666667%}
.paper .col-xs-7{width:58.33333333%}
.paper .col-xs-6{width:50%}
.paper .col-xs-5{width:41.66666667%}
.paper .col-xs-4{width:33.33333333%}
.paper .col-xs-3{width:25%}
.paper .col-xs-2{width:16.66666667%}
.paper .col-xs-1{width:8.33333333%}
.paper .col-xs-pull-12{right:100%}
.paper .col-xs-pull-11{right:91.66666667%}
.paper .col-xs-pull-10{right:83.33333333%}
.paper .col-xs-pull-9{right:75%}
.paper .col-xs-pull-8{right:66.66666667%}
.paper .col-xs-pull-7{right:58.33333333%}
.paper .col-xs-pull-6{right:50%}
.paper .col-xs-pull-5{right:41.66666667%}
.paper .col-xs-pull-4{right:33.33333333%}
.paper .col-xs-pull-3{right:25%}
.paper .col-xs-pull-2{right:16.66666667%}
.paper .col-xs-pull-1{right:8.33333333%}
.paper .col-xs-pull-0{right:auto}
.paper .col-xs-push-12{left:100%}
.paper .col-xs-push-11{left:91.66666667%}
.paper .col-xs-push-10{left:83.33333333%}
.paper .col-xs-push-9{left:75%}
.paper .col-xs-push-8{left:66.66666667%}
.paper .col-xs-push-7{left:58.33333333%}
.paper .col-xs-push-6{left:50%}
.paper .col-xs-push-5{left:41.66666667%}
.paper .col-xs-push-4{left:33.33333333%}
.paper .col-xs-push-3{left:25%}
.paper .col-xs-push-2{left:16.66666667%}
.paper .col-xs-push-1{left:8.33333333%}
.paper .col-xs-push-0{left:auto}
.paper .col-xs-offset-12{margin-left:100%}
.paper .col-xs-offset-11{margin-left:91.66666667%}
.paper .col-xs-offset-10{margin-left:83.33333333%}
.paper .col-xs-offset-9{margin-left:75%}
.paper .col-xs-offset-8{margin-left:66.66666667%}
.paper .col-xs-offset-7{margin-left:58.33333333%}
.paper .col-xs-offset-6{margin-left:50%}
.paper .col-xs-offset-5{margin-left:41.66666667%}
.paper .col-xs-offset-4{margin-left:33.33333333%}
.paper .col-xs-offset-3{margin-left:25%}
.paper .col-xs-offset-2{margin-left:16.66666667%}
.paper .col-xs-offset-1{margin-left:8.33333333%}
.paper .col-xs-offset-0{margin-left:0%}
@media (min-width:768px){
.paper .col-sm-1, .paper .col-sm-2, .paper .col-sm-3, .paper .col-sm-4, .paper .col-sm-5, .paper .col-sm-6, .paper .col-sm-7, .paper .col-sm-8, .paper .col-sm-9, .paper .col-sm-10, .paper .col-sm-11, .paper .col-sm-12{float:left}
.paper .col-sm-12{width:100%}
.paper .col-sm-11{width:91.66666667%}
.paper .col-sm-10{width:83.33333333%}
.paper .col-sm-9{width:75%}
.paper .col-sm-8{width:66.66666667%}
.paper .col-sm-7{width:58.33333333%}
.paper .col-sm-6{width:50%}
.paper .col-sm-5{width:41.66666667%}
.paper .col-sm-4{width:33.33333333%}
.paper .col-sm-3{width:25%}
.paper .col-sm-2{width:16.66666667%}
.paper .col-sm-1{width:8.33333333%}
.paper .col-sm-pull-12{right:100%}
.paper .col-sm-pull-11{right:91.66666667%}
.paper .col-sm-pull-10{right:83.33333333%}
.paper .col-sm-pull-9{right:75%}
.paper .col-sm-pull-8{right:66.66666667%}
.paper .col-sm-pull-7{right:58.33333333%}
.paper .col-sm-pull-6{right:50%}
.paper .col-sm-pull-5{right:41.66666667%}
.paper .col-sm-pull-4{right:33.33333333%}
.paper .col-sm-pull-3{right:25%}
.paper .col-sm-pull-2{right:16.66666667%}
.paper .col-sm-pull-1{right:8.33333333%}
.paper .col-sm-pull-0{right:auto}
.paper .col-sm-push-12{left:100%}
.paper .col-sm-push-11{left:91.66666667%}
.paper .col-sm-push-10{left:83.33333333%}
.paper .col-sm-push-9{left:75%}
.paper .col-sm-push-8{left:66.66666667%}
.paper .col-sm-push-7{left:58.33333333%}
.paper .col-sm-push-6{left:50%}
.paper .col-sm-push-5{left:41.66666667%}
.paper .col-sm-push-4{left:33.33333333%}
.paper .col-sm-push-3{left:25%}
.paper .col-sm-push-2{left:16.66666667%}
.paper .col-sm-push-1{left:8.33333333%}
.paper .col-sm-push-0{left:auto}
.paper .col-sm-offset-12{margin-left:100%}
.paper .col-sm-offset-11{margin-left:91.66666667%}
.paper .col-sm-offset-10{margin-left:83.33333333%}
.paper .col-sm-offset-9{margin-left:75%}
.paper .col-sm-offset-8{margin-left:66.66666667%}
.paper .col-sm-offset-7{margin-left:58.33333333%}
.paper .col-sm-offset-6{margin-left:50%}
.paper .col-sm-offset-5{margin-left:41.66666667%}
.paper .col-sm-offset-4{margin-left:33.33333333%}
.paper .col-sm-offset-3{margin-left:25%}
.paper .col-sm-offset-2{margin-left:16.66666667%}
.paper .col-sm-offset-1{margin-left:8.33333333%}
.paper .col-sm-offset-0{margin-left:0%}
}
@media (min-width:992px){
.paper .col-md-1, .paper .col-md-2, .paper .col-md-3, .paper .col-md-4, .paper .col-md-5, .paper .col-md-6, .paper .col-md-7, .paper .col-md-8, .paper .col-md-9, .paper .col-md-10, .paper .col-md-11, .paper .col-md-12{float:left}
.paper .col-md-12{width:100%}
.paper .col-md-11{width:91.66666667%}
.paper .col-md-10{width:83.33333333%}
.paper .col-md-9{width:75%}
.paper .col-md-8{width:66.66666667%}
.paper .col-md-7{width:58.33333333%}
.paper .col-md-6{width:50%}
.paper .col-md-5{width:41.66666667%}
.paper .col-md-4{width:33.33333333%}
.paper .col-md-3{width:25%}
.paper .col-md-2{width:16.66666667%}
.paper .col-md-1{width:8.33333333%}
.paper .col-md-pull-12{right:100%}
.paper .col-md-pull-11{right:91.66666667%}
.paper .col-md-pull-10{right:83.33333333%}
.paper .col-md-pull-9{right:75%}
.paper .col-md-pull-8{right:66.66666667%}
.paper .col-md-pull-7{right:58.33333333%}
.paper .col-md-pull-6{right:50%}
.paper .col-md-pull-5{right:41.66666667%}
.paper .col-md-pull-4{right:33.33333333%}
.paper .col-md-pull-3{right:25%}
.paper .col-md-pull-2{right:16.66666667%}
.paper .col-md-pull-1{right:8.33333333%}
.paper .col-md-pull-0{right:auto}
.paper .col-md-push-12{left:100%}
.paper .col-md-push-11{left:91.66666667%}
.paper .col-md-push-10{left:83.33333333%}
.paper .col-md-push-9{left:75%}
.paper .col-md-push-8{left:66.66666667%}
.paper .col-md-push-7{left:58.33333333%}
.paper .col-md-push-6{left:50%}
.paper .col-md-push-5{left:41.66666667%}
.paper .col-md-push-4{left:33.33333333%}
.paper .col-md-push-3{left:25%}
.paper .col-md-push-2{left:16.66666667%}
.paper .col-md-push-1{left:8.33333333%}
.paper .col-md-push-0{left:auto}
.paper .col-md-offset-12{margin-left:100%}
.paper .col-md-offset-11{margin-left:91.66666667%}
.paper .col-md-offset-10{margin-left:83.33333333%}
.paper .col-md-offset-9{margin-left:75%}
.paper .col-md-offset-8{margin-left:66.66666667%}
.paper .col-md-offset-7{margin-left:58.33333333%}
.paper .col-md-offset-6{margin-left:50%}
.paper .col-md-offset-5{margin-left:41.66666667%}
.paper .col-md-offset-4{margin-left:33.33333333%}
.paper .col-md-offset-3{margin-left:25%}
.paper .col-md-offset-2{margin-left:16.66666667%}
.paper .col-md-offset-1{margin-left:8.33333333%}
.paper .col-md-offset-0{margin-left:0%}
}
@media (min-width:1200px){
.paper .col-lg-1, .paper .col-lg-2, .paper .col-lg-3, .paper .col-lg-4, .paper .col-lg-5, .paper .col-lg-6, .paper .col-lg-7, .paper .col-lg-8, .paper .col-lg-9, .paper .col-lg-10, .paper .col-lg-11, .paper .col-lg-12{float:left}
.paper .col-lg-12{width:100%}
.paper .col-lg-11{width:91.66666667%}
.paper .col-lg-10{width:83.33333333%}
.paper .col-lg-9{width:75%}
.paper .col-lg-8{width:66.66666667%}
.paper .col-lg-7{width:58.33333333%}
.paper .col-lg-6{width:50%}
.paper .col-lg-5{width:41.66666667%}
.paper .col-lg-4{width:33.33333333%}
.paper .col-lg-3{width:25%}
.paper .col-lg-2{width:16.66666667%}
.paper .col-lg-1{width:8.33333333%}
.paper .col-lg-pull-12{right:100%}
.paper .col-lg-pull-11{right:91.66666667%}
.paper .col-lg-pull-10{right:83.33333333%}
.paper .col-lg-pull-9{right:75%}
.paper .col-lg-pull-8{right:66.66666667%}
.paper .col-lg-pull-7{right:58.33333333%}
.paper .col-lg-pull-6{right:50%}
.paper .col-lg-pull-5{right:41.66666667%}
.paper .col-lg-pull-4{right:33.33333333%}
.paper .col-lg-pull-3{right:25%}
.paper .col-lg-pull-2{right:16.66666667%}
.paper .col-lg-pull-1{right:8.33333333%}
.paper .col-lg-pull-0{right:auto}
.paper .col-lg-push-12{left:100%}
.paper .col-lg-push-11{left:91.66666667%}
.paper .col-lg-push-10{left:83.33333333%}
.paper .col-lg-push-9{left:75%}
.paper .col-lg-push-8{left:66.66666667%}
.paper .col-lg-push-7{left:58.33333333%}
.paper .col-lg-push-6{left:50%}
.paper .col-lg-push-5{left:41.66666667%}
.paper .col-lg-push-4{left:33.33333333%}
.paper .col-lg-push-3{left:25%}
.paper .col-lg-push-2{left:16.66666667%}
.paper .col-lg-push-1{left:8.33333333%}
.paper .col-lg-push-0{left:auto}
.paper .col-lg-offset-12{margin-left:100%}
.paper .col-lg-offset-11{margin-left:91.66666667%}
.paper .col-lg-offset-10{margin-left:83.33333333%}
.paper .col-lg-offset-9{margin-left:75%}
.paper .col-lg-offset-8{margin-left:66.66666667%}
.paper .col-lg-offset-7{margin-left:58.33333333%}
.paper .col-lg-offset-6{margin-left:50%}
.paper .col-lg-offset-5{margin-left:41.66666667%}
.paper .col-lg-offset-4{margin-left:33.33333333%}
.paper .col-lg-offset-3{margin-left:25%}
.paper .col-lg-offset-2{margin-left:16.66666667%}
.paper .col-lg-offset-1{margin-left:8.33333333%}
.paper .col-lg-offset-0{margin-left:0%}
}
.paper table{background-color:transparent}
.paper caption{padding-top:8px;padding-bottom:8px;color:#bbbbbb;text-align:left}
.paper th{text-align:left}
.paper .table{width:100%;max-width:100%;margin-bottom:23px}
.paper .table>thead>tr>th, .paper .table>tbody>tr>th, .paper .table>tfoot>tr>th, .paper .table>thead>tr>td, .paper .table>tbody>tr>td, .paper .table>tfoot>tr>td{padding:8px;line-height:1.846;vertical-align:top;border-top:1px solid #dddddd}
.paper .table>thead>tr>th{vertical-align:bottom;border-bottom:2px solid #dddddd}
.paper .table>caption+thead>tr:first-child>th, .paper .table>colgroup+thead>tr:first-child>th, .paper .table>thead:first-child>tr:first-child>th, .paper .table>caption+thead>tr:first-child>td, .paper .table>colgroup+thead>tr:first-child>td, .paper .table>thead:first-child>tr:first-child>td{border-top:0}
.paper .table>tbody+tbody{border-top:2px solid #dddddd}
.paper .table .table{background-color:#ffffff}
.paper .table-condensed>thead>tr>th, .paper .table-condensed>tbody>tr>th, .paper .table-condensed>tfoot>tr>th, .paper .table-condensed>thead>tr>td, .paper .table-condensed>tbody>tr>td, .paper .table-condensed>tfoot>tr>td{padding:5px}
.paper .table-bordered{border:1px solid #dddddd}
.paper .table-bordered>thead>tr>th, .paper .table-bordered>tbody>tr>th, .paper .table-bordered>tfoot>tr>th, .paper .table-bordered>thead>tr>td, .paper .table-bordered>tbody>tr>td, .paper .table-bordered>tfoot>tr>td{border:1px solid #dddddd}
.paper .table-bordered>thead>tr>th, .paper .table-bordered>thead>tr>td{border-bottom-width:2px}
.paper .table-striped>tbody>tr:nth-of-type(odd){background-color:#f9f9f9}
.paper .table-hover>tbody>tr:hover{background-color:#f5f5f5}
.paper table col[class*="col-"]{position:static;float:none;display:table-column}
.paper table td[class*="col-"], .paper table th[class*="col-"]{position:static;float:none;display:table-cell}
.paper .table>thead>tr>td.active, .paper .table>tbody>tr>td.active, .paper .table>tfoot>tr>td.active, .paper .table>thead>tr>th.active, .paper .table>tbody>tr>th.active, .paper .table>tfoot>tr>th.active, .paper .table>thead>tr.active>td, .paper .table>tbody>tr.active>td, .paper .table>tfoot>tr.active>td, .paper .table>thead>tr.active>th, .paper .table>tbody>tr.active>th, .paper .table>tfoot>tr.active>th{background-color:#f5f5f5}
.paper .table-hover>tbody>tr>td.active:hover, .paper .table-hover>tbody>tr>th.active:hover, .paper .table-hover>tbody>tr.active:hover>td, .paper .table-hover>tbody>tr:hover>.active, .paper .table-hover>tbody>tr.active:hover>th{background-color:#e8e8e8}
.paper .table>thead>tr>td.success, .paper .table>tbody>tr>td.success, .paper .table>tfoot>tr>td.success, .paper .table>thead>tr>th.success, .paper .table>tbody>tr>th.success, .paper .table>tfoot>tr>th.success, .paper .table>thead>tr.success>td, .paper .table>tbody>tr.success>td, .paper .table>tfoot>tr.success>td, .paper .table>thead>tr.success>th, .paper .table>tbody>tr.success>th, .paper .table>tfoot>tr.success>th{background-color:#dff0d8}
.paper .table-hover>tbody>tr>td.success:hover, .paper .table-hover>tbody>tr>th.success:hover, .paper .table-hover>tbody>tr.success:hover>td, .paper .table-hover>tbody>tr:hover>.success, .paper .table-hover>tbody>tr.success:hover>th{background-color:#d0e9c6}
.paper .table>thead>tr>td.info, .paper .table>tbody>tr>td.info, .paper .table>tfoot>tr>td.info, .paper .table>thead>tr>th.info, .paper .table>tbody>tr>th.info, .paper .table>tfoot>tr>th.info, .paper .table>thead>tr.info>td, .paper .table>tbody>tr.info>td, .paper .table>tfoot>tr.info>td, .paper .table>thead>tr.info>th, .paper .table>tbody>tr.info>th, .paper .table>tfoot>tr.info>th{background-color:#e1bee7}
.paper .table-hover>tbody>tr>td.info:hover, .paper .table-hover>tbody>tr>th.info:hover, .paper .table-hover>tbody>tr.info:hover>td, .paper .table-hover>tbody>tr:hover>.info, .paper .table-hover>tbody>tr.info:hover>th{background-color:#d8abe0}
.paper .table>thead>tr>td.warning, .paper .table>tbody>tr>td.warning, .paper .table>tfoot>tr>td.warning, .paper .table>thead>tr>th.warning, .paper .table>tbody>tr>th.warning, .paper .table>tfoot>tr>th.warning, .paper .table>thead>tr.warning>td, .paper .table>tbody>tr.warning>td, .paper .table>tfoot>tr.warning>td, .paper .table>thead>tr.warning>th, .paper .table>tbody>tr.warning>th, .paper .table>tfoot>tr.warning>th{background-color:#ffe0b2}
.paper .table-hover>tbody>tr>td.warning:hover, .paper .table-hover>tbody>tr>th.warning:hover, .paper .table-hover>tbody>tr.warning:hover>td, .paper .table-hover>tbody>tr:hover>.warning, .paper .table-hover>tbody>tr.warning:hover>th{background-color:#ffd699}
.paper .table>thead>tr>td.danger, .paper .table>tbody>tr>td.danger, .paper .table>tfoot>tr>td.danger, .paper .table>thead>tr>th.danger, .paper .table>tbody>tr>th.danger, .paper .table>tfoot>tr>th.danger, .paper .table>thead>tr.danger>td, .paper .table>tbody>tr.danger>td, .paper .table>tfoot>tr.danger>td, .paper .table>thead>tr.danger>th, .paper .table>tbody>tr.danger>th, .paper .table>tfoot>tr.danger>th{background-color:#f9bdbb}
.paper .table-hover>tbody>tr>td.danger:hover, .paper .table-hover>tbody>tr>th.danger:hover, .paper .table-hover>tbody>tr.danger:hover>td, .paper .table-hover>tbody>tr:hover>.danger, .paper .table-hover>tbody>tr.danger:hover>th{background-color:#f7a6a4}
.paper .table-responsive{overflow-x:auto;min-height:0.01%}
@media screen and (max-width:767px){
.paper .table-responsive{width:100%;margin-bottom:17.25px;overflow-y:hidden;-ms-overflow-style:-ms-autohiding-scrollbar;border:1px solid #dddddd}
.paper .table-responsive>.table{margin-bottom:0}
.paper .table-responsive>.table>thead>tr>th, .paper .table-responsive>.table>tbody>tr>th, .paper .table-responsive>.table>tfoot>tr>th, .paper .table-responsive>.table>thead>tr>td, .paper .table-responsive>.table>tbody>tr>td, .paper .table-responsive>.table>tfoot>tr>td{white-space:nowrap}
.paper .table-responsive>.table-bordered{border:0}
.paper .table-responsive>.table-bordered>thead>tr>th:first-child, .paper .table-responsive>.table-bordered>tbody>tr>th:first-child, .paper .table-responsive>.table-bordered>tfoot>tr>th:first-child, .paper .table-responsive>.table-bordered>thead>tr>td:first-child, .paper .table-responsive>.table-bordered>tbody>tr>td:first-child, .paper .table-responsive>.table-bordered>tfoot>tr>td:first-child{border-left:0}
.paper .table-responsive>.table-bordered>thead>tr>th:last-child, .paper .table-responsive>.table-bordered>tbody>tr>th:last-child, .paper .table-responsive>.table-bordered>tfoot>tr>th:last-child, .paper .table-responsive>.table-bordered>thead>tr>td:last-child, .paper .table-responsive>.table-bordered>tbody>tr>td:last-child, .paper .table-responsive>.table-bordered>tfoot>tr>td:last-child{border-right:0}
.paper .table-responsive>.table-bordered>tbody>tr:last-child>th, .paper .table-responsive>.table-bordered>tfoot>tr:last-child>th, .paper .table-responsive>.table-bordered>tbody>tr:last-child>td, .paper .table-responsive>.table-bordered>tfoot>tr:last-child>td{border-bottom:0}
}
.paper fieldset{padding:0;margin:0;border:0;min-width:0}
.paper legend{display:block;width:100%;padding:0;margin-bottom:23px;font-size:19.5px;line-height:inherit;color:#212121;border:0;border-bottom:1px solid #e5e5e5}
.paper label{display:inline-block;max-width:100%;margin-bottom:5px;font-weight:bold}
.paper input[type="search"]{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
.paper input[type="radio"], .paper input[type="checkbox"]{margin:4px 0 0;margin-top:1px \9;line-height:normal}
.paper input[type="file"]{display:block}
.paper input[type="range"]{display:block;width:100%}
.paper select[multiple], .paper select[size]{height:auto}
.paper input[type="file"]:focus, .paper input[type="radio"]:focus, .paper input[type="checkbox"]:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px}
.paper output{display:block;padding-top:7px;font-size:13px;line-height:1.846;color:#666666}
.paper .form-control{display:block;width:100%;height:37px;padding:6px 16px;font-size:13px;line-height:1.846;color:#666666;background-color:transparent;background-image:none;border:1px solid transparent;border-radius:3px;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);-webkit-transition:border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;-o-transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s}
.paper .form-control:focus{border-color:#66afe9;outline:0;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(102,175,233,0.6);box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 8px rgba(102,175,233,0.6)}
.paper .form-control::-moz-placeholder{color:#bbbbbb;opacity:1}
.paper .form-control:-ms-input-placeholder{color:#bbbbbb}
.paper .form-control::-webkit-input-placeholder{color:#bbbbbb}
.paper .form-control::-ms-expand{border:0;background-color:transparent}
.paper .form-control[disabled], .paper .form-control[readonly], .paper fieldset[disabled] .form-control{background-color:transparent;opacity:1}
.paper .form-control[disabled], .paper fieldset[disabled] .form-control{cursor:not-allowed}
.paper textarea.form-control{height:auto}
.paper input[type="search"]{-webkit-appearance:none}
@media screen and (-webkit-min-device-pixel-ratio:0){
.paper input[type="date"].form-control, .paper input[type="time"].form-control, .paper input[type="datetime-local"].form-control, .paper input[type="month"].form-control{line-height:37px}
.paper input[type="date"].input-sm, .paper input[type="time"].input-sm, .paper input[type="datetime-local"].input-sm, .paper input[type="month"].input-sm, .paper .input-group-sm input[type="date"], .paper .input-group-sm input[type="time"], .paper .input-group-sm input[type="datetime-local"], .paper .input-group-sm input[type="month"]{line-height:30px}
.paper input[type="date"].input-lg, .paper input[type="time"].input-lg, .paper input[type="datetime-local"].input-lg, .paper input[type="month"].input-lg, .paper .input-group-lg input[type="date"], .paper .input-group-lg input[type="time"], .paper .input-group-lg input[type="datetime-local"], .paper .input-group-lg input[type="month"]{line-height:45px}
}
.paper .form-group{margin-bottom:15px}
.paper .radio, .paper .checkbox{position:relative;display:block;margin-top:10px;margin-bottom:10px}
.paper .radio label, .paper .checkbox label{min-height:23px;padding-left:20px;margin-bottom:0;font-weight:normal;cursor:pointer}
.paper .radio input[type="radio"], .paper .radio-inline input[type="radio"], .paper .checkbox input[type="checkbox"], .paper .checkbox-inline input[type="checkbox"]{position:absolute;margin-left:-20px;margin-top:4px \9}
.paper .radio+.radio, .paper .checkbox+.checkbox{margin-top:-5px}
.paper .radio-inline, .paper .checkbox-inline{position:relative;display:inline-block;padding-left:20px;margin-bottom:0;vertical-align:middle;font-weight:normal;cursor:pointer}
.paper .radio-inline+.radio-inline, .paper .checkbox-inline+.checkbox-inline{margin-top:0;margin-left:10px}
.paper input[type="radio"][disabled], .paper input[type="checkbox"][disabled], .paper input[type="radio"].disabled, .paper input[type="checkbox"].disabled, .paper fieldset[disabled] input[type="radio"], .paper fieldset[disabled] input[type="checkbox"]{cursor:not-allowed}
.paper .radio-inline.disabled, .paper .checkbox-inline.disabled, .paper fieldset[disabled] .radio-inline, .paper fieldset[disabled] .checkbox-inline{cursor:not-allowed}
.paper .radio.disabled label, .paper .checkbox.disabled label, .paper fieldset[disabled] .radio label, .paper fieldset[disabled] .checkbox label{cursor:not-allowed}
.paper .form-control-static{padding-top:7px;padding-bottom:7px;margin-bottom:0;min-height:36px}
.paper .form-control-static.input-lg, .paper .form-control-static.input-sm{padding-left:0;padding-right:0}
.paper .input-sm{height:30px;padding:5px 10px;font-size:12px;line-height:1.5;border-radius:3px}
.paper select.input-sm{height:30px;line-height:30px}
.paper textarea.input-sm, .paper select[multiple].input-sm{height:auto}
.paper .form-group-sm .form-control{height:30px;padding:5px 10px;font-size:12px;line-height:1.5;border-radius:3px}
.paper .form-group-sm select.form-control{height:30px;line-height:30px}
.paper .form-group-sm textarea.form-control, .paper .form-group-sm select[multiple].form-control{height:auto}
.paper .form-group-sm .form-control-static{height:30px;min-height:35px;padding:6px 10px;font-size:12px;line-height:1.5}
.paper .input-lg{height:45px;padding:10px 16px;font-size:17px;line-height:1.3333333;border-radius:3px}
.paper select.input-lg{height:45px;line-height:45px}
.paper textarea.input-lg, .paper select[multiple].input-lg{height:auto}
.paper .form-group-lg .form-control{height:45px;padding:10px 16px;font-size:17px;line-height:1.3333333;border-radius:3px}
.paper .form-group-lg select.form-control{height:45px;line-height:45px}
.paper .form-group-lg textarea.form-control, .paper .form-group-lg select[multiple].form-control{height:auto}
.paper .form-group-lg .form-control-static{height:45px;min-height:40px;padding:11px 16px;font-size:17px;line-height:1.3333333}
.paper .has-feedback{position:relative}
.paper .has-feedback .form-control{padding-right:46.25px}
.paper .form-control-feedback{position:absolute;top:0;right:0;z-index:2;display:block;width:37px;height:37px;line-height:37px;text-align:center;pointer-events:none}
.paper .input-lg+.form-control-feedback, .paper .input-group-lg+.form-control-feedback, .paper .form-group-lg .form-control+.form-control-feedback{width:45px;height:45px;line-height:45px}
.paper .input-sm+.form-control-feedback, .paper .input-group-sm+.form-control-feedback, .paper .form-group-sm .form-control+.form-control-feedback{width:30px;height:30px;line-height:30px}
.paper .has-success .help-block, .paper .has-success .control-label, .paper .has-success .radio, .paper .has-success .checkbox, .paper .has-success .radio-inline, .paper .has-success .checkbox-inline, .paper .has-success.radio label, .paper .has-success.checkbox label, .paper .has-success.radio-inline label, .paper .has-success.checkbox-inline label{color:#4caf50}
.paper .has-success .form-control{border-color:#4caf50;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);box-shadow:inset 0 1px 1px rgba(0,0,0,0.075)}
.paper .has-success .form-control:focus{border-color:#3d8b40;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #92cf94;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #92cf94}
.paper .has-success .input-group-addon{color:#4caf50;border-color:#4caf50;background-color:#dff0d8}
.paper .has-success .form-control-feedback{color:#4caf50}
.paper .has-warning .help-block, .paper .has-warning .control-label, .paper .has-warning .radio, .paper .has-warning .checkbox, .paper .has-warning .radio-inline, .paper .has-warning .checkbox-inline, .paper .has-warning.radio label, .paper .has-warning.checkbox label, .paper .has-warning.radio-inline label, .paper .has-warning.checkbox-inline label{color:#ff9800}
.paper .has-warning .form-control{border-color:#ff9800;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);box-shadow:inset 0 1px 1px rgba(0,0,0,0.075)}
.paper .has-warning .form-control:focus{border-color:#cc7a00;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #ffc166;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #ffc166}
.paper .has-warning .input-group-addon{color:#ff9800;border-color:#ff9800;background-color:#ffe0b2}
.paper .has-warning .form-control-feedback{color:#ff9800}
.paper .has-error .help-block, .paper .has-error .control-label, .paper .has-error .radio, .paper .has-error .checkbox, .paper .has-error .radio-inline, .paper .has-error .checkbox-inline, .paper .has-error.radio label, .paper .has-error.checkbox label, .paper .has-error.radio-inline label, .paper .has-error.checkbox-inline label{color:#e51c23}
.paper .has-error .form-control{border-color:#e51c23;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075);box-shadow:inset 0 1px 1px rgba(0,0,0,0.075)}
.paper .has-error .form-control:focus{border-color:#b9151b;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #ef787c;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 6px #ef787c}
.paper .has-error .input-group-addon{color:#e51c23;border-color:#e51c23;background-color:#f9bdbb}
.paper .has-error .form-control-feedback{color:#e51c23}
.paper .has-feedback label~.form-control-feedback{top:28px}
.paper .has-feedback label.sr-only~.form-control-feedback{top:0}
.paper .help-block{display:block;margin-top:5px;margin-bottom:10px;color:#a6a6a6}
@media (min-width:768px){
.paper .form-inline .form-group{display:inline-block;margin-bottom:0;vertical-align:middle}
.paper .form-inline .form-control{display:inline-block;width:auto;vertical-align:middle}
.paper .form-inline .form-control-static{display:inline-block}
.paper .form-inline .input-group{display:inline-table;vertical-align:middle}
.paper .form-inline .input-group .input-group-addon, .paper .form-inline .input-group .input-group-btn, .paper .form-inline .input-group .form-control{width:auto}
.paper .form-inline .input-group>.form-control{width:100%}
.paper .form-inline .control-label{margin-bottom:0;vertical-align:middle}
.paper .form-inline .radio, .paper .form-inline .checkbox{display:inline-block;margin-top:0;margin-bottom:0;vertical-align:middle}
.paper .form-inline .radio label, .paper .form-inline .checkbox label{padding-left:0}
.paper .form-inline .radio input[type="radio"], .paper .form-inline .checkbox input[type="checkbox"]{position:relative;margin-left:0}
.paper .form-inline .has-feedback .form-control-feedback{top:0}
}
.paper .form-horizontal .radio, .paper .form-horizontal .checkbox, .paper .form-horizontal .radio-inline, .paper .form-horizontal .checkbox-inline{margin-top:0;margin-bottom:0;padding-top:7px}
.paper .form-horizontal .radio, .paper .form-horizontal .checkbox{min-height:30px}
.paper .form-horizontal .form-group{margin-left:-15px;margin-right:-15px}
@media (min-width:768px){
.paper .form-horizontal .control-label{text-align:right;margin-bottom:0;padding-top:7px}
}
.paper .form-horizontal .has-feedback .form-control-feedback{right:15px}
@media (min-width:768px){
.paper .form-horizontal .form-group-lg .control-label{padding-top:11px;font-size:17px}
}
@media (min-width:768px){
.paper .form-horizontal .form-group-sm .control-label{padding-top:6px;font-size:12px}
}
.paper .btn{display:inline-block;margin-bottom:0;font-weight:normal;text-align:center;vertical-align:middle;-ms-touch-action:manipulation;touch-action:manipulation;cursor:pointer;background-image:none;border:1px solid transparent;white-space:nowrap;padding:6px 16px;font-size:13px;line-height:1.846;border-radius:3px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}
.paper .btn:focus, .paper .btn:active:focus, .paper .btn.active:focus, .paper .btn.focus, .paper .btn:active.focus, .paper .btn.active.focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px}
.paper .btn:hover, .paper .btn:focus, .paper .btn.focus{color:#444444;text-decoration:none}
.paper .btn:active, .paper .btn.active{outline:0;background-image:none;-webkit-box-shadow:inset 0 3px 5px rgba(0,0,0,0.125);box-shadow:inset 0 3px 5px rgba(0,0,0,0.125)}
.paper .btn.disabled, .paper .btn[disabled], .paper fieldset[disabled] .btn{cursor:not-allowed;opacity:0.65;filter:alpha(opacity=65);-webkit-box-shadow:none;box-shadow:none}
.paper a.btn.disabled, .paper fieldset[disabled] a.btn{pointer-events:none}
.paper .btn-default{color:#444444;background-color:#ffffff;border-color:transparent}
.paper .btn-default:focus, .paper .btn-default.focus{color:#444444;background-color:#e6e6e6;border-color:rgba(0,0,0,0)}
.paper .btn-default:hover{color:#444444;background-color:#e6e6e6;border-color:rgba(0,0,0,0)}
.paper .btn-default:active, .paper .btn-default.active, .paper .open>.dropdown-toggle.btn-default{color:#444444;background-color:#e6e6e6;border-color:rgba(0,0,0,0)}
.paper .btn-default:active:hover, .paper .btn-default.active:hover, .paper .open>.dropdown-toggle.btn-default:hover, .paper .btn-default:active:focus, .paper .btn-default.active:focus, .paper .open>.dropdown-toggle.btn-default:focus, .paper .btn-default:active.focus, .paper .btn-default.active.focus, .paper .open>.dropdown-toggle.btn-default.focus{color:#444444;background-color:#d4d4d4;border-color:rgba(0,0,0,0)}
.paper .btn-default:active, .paper .btn-default.active, .paper .open>.dropdown-toggle.btn-default{background-image:none}
.paper .btn-default.disabled:hover, .paper .btn-default[disabled]:hover, .paper fieldset[disabled] .btn-default:hover, .paper .btn-default.disabled:focus, .paper .btn-default[disabled]:focus, .paper fieldset[disabled] .btn-default:focus, .paper .btn-default.disabled.focus, .paper .btn-default[disabled].focus, .paper fieldset[disabled] .btn-default.focus{background-color:#ffffff;border-color:transparent}
.paper .btn-default .badge{color:#ffffff;background-color:#444444}
.paper .btn-primary{color:#ffffff;background-color:#2196f3;border-color:transparent}
.paper .btn-primary:focus, .paper .btn-primary.focus{color:#ffffff;background-color:#0c7cd5;border-color:rgba(0,0,0,0)}
.paper .btn-primary:hover{color:#ffffff;background-color:#0c7cd5;border-color:rgba(0,0,0,0)}
.paper .btn-primary:active, .paper .btn-primary.active, .paper .open>.dropdown-toggle.btn-primary{color:#ffffff;background-color:#0c7cd5;border-color:rgba(0,0,0,0)}
.paper .btn-primary:active:hover, .paper .btn-primary.active:hover, .paper .open>.dropdown-toggle.btn-primary:hover, .paper .btn-primary:active:focus, .paper .btn-primary.active:focus, .paper .open>.dropdown-toggle.btn-primary:focus, .paper .btn-primary:active.focus, .paper .btn-primary.active.focus, .paper .open>.dropdown-toggle.btn-primary.focus{color:#ffffff;background-color:#0a68b4;border-color:rgba(0,0,0,0)}
.paper .btn-primary:active, .paper .btn-primary.active, .paper .open>.dropdown-toggle.btn-primary{background-image:none}
.paper .btn-primary.disabled:hover, .paper .btn-primary[disabled]:hover, .paper fieldset[disabled] .btn-primary:hover, .paper .btn-primary.disabled:focus, .paper .btn-primary[disabled]:focus, .paper fieldset[disabled] .btn-primary:focus, .paper .btn-primary.disabled.focus, .paper .btn-primary[disabled].focus, .paper fieldset[disabled] .btn-primary.focus{background-color:#2196f3;border-color:transparent}
.paper .btn-primary .badge{color:#2196f3;background-color:#ffffff}
.paper .btn-success{color:#ffffff;background-color:#4caf50;border-color:transparent}
.paper .btn-success:focus, .paper .btn-success.focus{color:#ffffff;background-color:#3d8b40;border-color:rgba(0,0,0,0)}
.paper .btn-success:hover{color:#ffffff;background-color:#3d8b40;border-color:rgba(0,0,0,0)}
.paper .btn-success:active, .paper .btn-success.active, .paper .open>.dropdown-toggle.btn-success{color:#ffffff;background-color:#3d8b40;border-color:rgba(0,0,0,0)}
.paper .btn-success:active:hover, .paper .btn-success.active:hover, .paper .open>.dropdown-toggle.btn-success:hover, .paper .btn-success:active:focus, .paper .btn-success.active:focus, .paper .open>.dropdown-toggle.btn-success:focus, .paper .btn-success:active.focus, .paper .btn-success.active.focus, .paper .open>.dropdown-toggle.btn-success.focus{color:#ffffff;background-color:#327334;border-color:rgba(0,0,0,0)}
.paper .btn-success:active, .paper .btn-success.active, .paper .open>.dropdown-toggle.btn-success{background-image:none}
.paper .btn-success.disabled:hover, .paper .btn-success[disabled]:hover, .paper fieldset[disabled] .btn-success:hover, .paper .btn-success.disabled:focus, .paper .btn-success[disabled]:focus, .paper fieldset[disabled] .btn-success:focus, .paper .btn-success.disabled.focus, .paper .btn-success[disabled].focus, .paper fieldset[disabled] .btn-success.focus{background-color:#4caf50;border-color:transparent}
.paper .btn-success .badge{color:#4caf50;background-color:#ffffff}
.paper .btn-info{color:#ffffff;background-color:#9c27b0;border-color:transparent}
.paper .btn-info:focus, .paper .btn-info.focus{color:#ffffff;background-color:#771e86;border-color:rgba(0,0,0,0)}
.paper .btn-info:hover{color:#ffffff;background-color:#771e86;border-color:rgba(0,0,0,0)}
.paper .btn-info:active, .paper .btn-info.active, .paper .open>.dropdown-toggle.btn-info{color:#ffffff;background-color:#771e86;border-color:rgba(0,0,0,0)}
.paper .btn-info:active:hover, .paper .btn-info.active:hover, .paper .open>.dropdown-toggle.btn-info:hover, .paper .btn-info:active:focus, .paper .btn-info.active:focus, .paper .open>.dropdown-toggle.btn-info:focus, .paper .btn-info:active.focus, .paper .btn-info.active.focus, .paper .open>.dropdown-toggle.btn-info.focus{color:#ffffff;background-color:#5d1769;border-color:rgba(0,0,0,0)}
.paper .btn-info:active, .paper .btn-info.active, .paper .open>.dropdown-toggle.btn-info{background-image:none}
.paper .btn-info.disabled:hover, .paper .btn-info[disabled]:hover, .paper fieldset[disabled] .btn-info:hover, .paper .btn-info.disabled:focus, .paper .btn-info[disabled]:focus, .paper fieldset[disabled] .btn-info:focus, .paper .btn-info.disabled.focus, .paper .btn-info[disabled].focus, .paper fieldset[disabled] .btn-info.focus{background-color:#9c27b0;border-color:transparent}
.paper .btn-info .badge{color:#9c27b0;background-color:#ffffff}
.paper .btn-warning{color:#ffffff;background-color:#ff9800;border-color:transparent}
.paper .btn-warning:focus, .paper .btn-warning.focus{color:#ffffff;background-color:#cc7a00;border-color:rgba(0,0,0,0)}
.paper .btn-warning:hover{color:#ffffff;background-color:#cc7a00;border-color:rgba(0,0,0,0)}
.paper .btn-warning:active, .paper .btn-warning.active, .paper .open>.dropdown-toggle.btn-warning{color:#ffffff;background-color:#cc7a00;border-color:rgba(0,0,0,0)}
.paper .btn-warning:active:hover, .paper .btn-warning.active:hover, .paper .open>.dropdown-toggle.btn-warning:hover, .paper .btn-warning:active:focus, .paper .btn-warning.active:focus, .paper .open>.dropdown-toggle.btn-warning:focus, .paper .btn-warning:active.focus, .paper .btn-warning.active.focus, .paper .open>.dropdown-toggle.btn-warning.focus{color:#ffffff;background-color:#a86400;border-color:rgba(0,0,0,0)}
.paper .btn-warning:active, .paper .btn-warning.active, .paper .open>.dropdown-toggle.btn-warning{background-image:none}
.paper .btn-warning.disabled:hover, .paper .btn-warning[disabled]:hover, .paper fieldset[disabled] .btn-warning:hover, .paper .btn-warning.disabled:focus, .paper .btn-warning[disabled]:focus, .paper fieldset[disabled] .btn-warning:focus, .paper .btn-warning.disabled.focus, .paper .btn-warning[disabled].focus, .paper fieldset[disabled] .btn-warning.focus{background-color:#ff9800;border-color:transparent}
.paper .btn-warning .badge{color:#ff9800;background-color:#ffffff}
.paper .btn-danger{color:#ffffff;background-color:#e51c23;border-color:transparent}
.paper .btn-danger:focus, .paper .btn-danger.focus{color:#ffffff;background-color:#b9151b;border-color:rgba(0,0,0,0)}
.paper .btn-danger:hover{color:#ffffff;background-color:#b9151b;border-color:rgba(0,0,0,0)}
.paper .btn-danger:active, .paper .btn-danger.active, .paper .open>.dropdown-toggle.btn-danger{color:#ffffff;background-color:#b9151b;border-color:rgba(0,0,0,0)}
.paper .btn-danger:active:hover, .paper .btn-danger.active:hover, .paper .open>.dropdown-toggle.btn-danger:hover, .paper .btn-danger:active:focus, .paper .btn-danger.active:focus, .paper .open>.dropdown-toggle.btn-danger:focus, .paper .btn-danger:active.focus, .paper .btn-danger.active.focus, .paper .open>.dropdown-toggle.btn-danger.focus{color:#ffffff;background-color:#991216;border-color:rgba(0,0,0,0)}
.paper .btn-danger:active, .paper .btn-danger.active, .paper .open>.dropdown-toggle.btn-danger{background-image:none}
.paper .btn-danger.disabled:hover, .paper .btn-danger[disabled]:hover, .paper fieldset[disabled] .btn-danger:hover, .paper .btn-danger.disabled:focus, .paper .btn-danger[disabled]:focus, .paper fieldset[disabled] .btn-danger:focus, .paper .btn-danger.disabled.focus, .paper .btn-danger[disabled].focus, .paper fieldset[disabled] .btn-danger.focus{background-color:#e51c23;border-color:transparent}
.paper .btn-danger .badge{color:#e51c23;background-color:#ffffff}
.paper .btn-link{color:#2196f3;font-weight:normal;border-radius:0}
.paper .btn-link, .paper .btn-link:active, .paper .btn-link.active, .paper .btn-link[disabled], .paper fieldset[disabled] .btn-link{background-color:transparent;-webkit-box-shadow:none;box-shadow:none}
.paper .btn-link, .paper .btn-link:hover, .paper .btn-link:focus, .paper .btn-link:active{border-color:transparent}
.paper .btn-link:hover, .paper .btn-link:focus{color:#0a6ebd;text-decoration:underline;background-color:transparent}
.paper .btn-link[disabled]:hover, .paper fieldset[disabled] .btn-link:hover, .paper .btn-link[disabled]:focus, .paper fieldset[disabled] .btn-link:focus{color:#bbbbbb;text-decoration:none}
.paper .btn-lg, .paper .btn-group-lg>.btn{padding:10px 16px;font-size:17px;line-height:1.3333333;border-radius:3px}
.paper .btn-sm, .paper .btn-group-sm>.btn{padding:5px 10px;font-size:12px;line-height:1.5;border-radius:3px}
.paper .btn-xs, .paper .btn-group-xs>.btn{padding:1px 5px;font-size:12px;line-height:1.5;border-radius:3px}
.paper .btn-block{display:block;width:100%}
.paper .btn-block+.btn-block{margin-top:5px}
.paper input[type="submit"].btn-block, .paper input[type="reset"].btn-block, .paper input[type="button"].btn-block{width:100%}
.paper .fade{opacity:0;-webkit-transition:opacity 0.15s linear;-o-transition:opacity 0.15s linear;transition:opacity 0.15s linear}
.paper .fade.in{opacity:1}
.paper .collapse{display:none}
.paper .collapse.in{display:block}
.paper tr.collapse.in{display:table-row}
.paper tbody.collapse.in{display:table-row-group}
.paper .collapsing{position:relative;height:0;overflow:hidden;-webkit-transition-property:height, visibility;-o-transition-property:height, visibility;transition-property:height, visibility;-webkit-transition-duration:0.35s;-o-transition-duration:0.35s;transition-duration:0.35s;-webkit-transition-timing-function:ease;-o-transition-timing-function:ease;transition-timing-function:ease}
.paper .caret{display:inline-block;width:0;height:0;margin-left:2px;vertical-align:middle;border-top:4px dashed;border-top:4px solid \9;border-right:4px solid transparent;border-left:4px solid transparent}
.paper .dropup, .paper .dropdown{position:relative}
.paper .dropdown-toggle:focus{outline:0}
.paper .dropdown-menu{position:absolute;top:100%;left:0;z-index:1000;display:none;float:left;min-width:160px;padding:5px 0;margin:2px 0 0;list-style:none;font-size:13px;text-align:left;background-color:#ffffff;border:1px solid #cccccc;border:1px solid rgba(0,0,0,0.15);border-radius:3px;-webkit-box-shadow:0 6px 12px rgba(0,0,0,0.175);box-shadow:0 6px 12px rgba(0,0,0,0.175);-webkit-background-clip:padding-box;background-clip:padding-box}
.paper .dropdown-menu.pull-right{right:0;left:auto}
.paper .dropdown-menu .divider{height:1px;margin:10.5px 0;overflow:hidden;background-color:#e5e5e5}
.paper .dropdown-menu>li>a{display:block;padding:3px 20px;clear:both;font-weight:normal;line-height:1.846;color:#666666;white-space:nowrap}
.paper .dropdown-menu>li>a:hover, .paper .dropdown-menu>li>a:focus{text-decoration:none;color:#141414;background-color:#eeeeee}
.paper .dropdown-menu>.active>a, .paper .dropdown-menu>.active>a:hover, .paper .dropdown-menu>.active>a:focus{color:#ffffff;text-decoration:none;outline:0;background-color:#2196f3}
.paper .dropdown-menu>.disabled>a, .paper .dropdown-menu>.disabled>a:hover, .paper .dropdown-menu>.disabled>a:focus{color:#bbbbbb}
.paper .dropdown-menu>.disabled>a:hover, .paper .dropdown-menu>.disabled>a:focus{text-decoration:none;background-color:transparent;background-image:none;filter:progid:DXImageTransform.Microsoft.gradient(enabled=false);cursor:not-allowed}
.paper .open>.dropdown-menu{display:block}
.paper .open>a{outline:0}
.paper .dropdown-menu-right{left:auto;right:0}
.paper .dropdown-menu-left{left:0;right:auto}
.paper .dropdown-header{display:block;padding:3px 20px;font-size:12px;line-height:1.846;color:#bbbbbb;white-space:nowrap}
.paper .dropdown-backdrop{position:fixed;left:0;right:0;bottom:0;top:0;z-index:990}
.paper .pull-right>.dropdown-menu{right:0;left:auto}
.paper .dropup .caret, .paper .navbar-fixed-bottom .dropdown .caret{border-top:0;border-bottom:4px dashed;border-bottom:4px solid \9;content:""}
.paper .dropup .dropdown-menu, .paper .navbar-fixed-bottom .dropdown .dropdown-menu{top:auto;bottom:100%;margin-bottom:2px}
@media (min-width:768px){
.paper .navbar-right .dropdown-menu{left:auto;right:0}
.paper .navbar-right .dropdown-menu-left{left:0;right:auto}
}
.paper .btn-group, .paper .btn-group-vertical{position:relative;display:inline-block;vertical-align:middle}
.paper .btn-group>.btn, .paper .btn-group-vertical>.btn{position:relative;float:left}
.paper .btn-group>.btn:hover, .paper .btn-group-vertical>.btn:hover, .paper .btn-group>.btn:focus, .paper .btn-group-vertical>.btn:focus, .paper .btn-group>.btn:active, .paper .btn-group-vertical>.btn:active, .paper .btn-group>.btn.active, .paper .btn-group-vertical>.btn.active{z-index:2}
.paper .btn-group .btn+.btn, .paper .btn-group .btn+.btn-group, .paper .btn-group .btn-group+.btn, .paper .btn-group .btn-group+.btn-group{margin-left:-1px}
.paper .btn-toolbar{margin-left:-5px}
.paper .btn-toolbar .btn, .paper .btn-toolbar .btn-group, .paper .btn-toolbar .input-group{float:left}
.paper .btn-toolbar>.btn, .paper .btn-toolbar>.btn-group, .paper .btn-toolbar>.input-group{margin-left:5px}
.paper .btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle){border-radius:0}
.paper .btn-group>.btn:first-child{margin-left:0}
.paper .btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle){border-bottom-right-radius:0;border-top-right-radius:0}
.paper .btn-group>.btn:last-child:not(:first-child), .paper .btn-group>.dropdown-toggle:not(:first-child){border-bottom-left-radius:0;border-top-left-radius:0}
.paper .btn-group>.btn-group{float:left}
.paper .btn-group>.btn-group:not(:first-child):not(:last-child)>.btn{border-radius:0}
.paper .btn-group>.btn-group:first-child:not(:last-child)>.btn:last-child, .paper .btn-group>.btn-group:first-child:not(:last-child)>.dropdown-toggle{border-bottom-right-radius:0;border-top-right-radius:0}
.paper .btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child{border-bottom-left-radius:0;border-top-left-radius:0}
.paper .btn-group .dropdown-toggle:active, .paper .btn-group.open .dropdown-toggle{outline:0}
.paper .btn-group>.btn+.dropdown-toggle{padding-left:8px;padding-right:8px}
.paper .btn-group>.btn-lg+.dropdown-toggle{padding-left:12px;padding-right:12px}
.paper .btn-group.open .dropdown-toggle{-webkit-box-shadow:inset 0 3px 5px rgba(0,0,0,0.125);box-shadow:inset 0 3px 5px rgba(0,0,0,0.125)}
.paper .btn-group.open .dropdown-toggle.btn-link{-webkit-box-shadow:none;box-shadow:none}
.paper .btn .caret{margin-left:0}
.paper .btn-lg .caret{border-width:5px 5px 0;border-bottom-width:0}
.paper .dropup .btn-lg .caret{border-width:0 5px 5px}
.paper .btn-group-vertical>.btn, .paper .btn-group-vertical>.btn-group, .paper .btn-group-vertical>.btn-group>.btn{display:block;float:none;width:100%;max-width:100%}
.paper .btn-group-vertical>.btn-group>.btn{float:none}
.paper .btn-group-vertical>.btn+.btn, .paper .btn-group-vertical>.btn+.btn-group, .paper .btn-group-vertical>.btn-group+.btn, .paper .btn-group-vertical>.btn-group+.btn-group{margin-top:-1px;margin-left:0}
.paper .btn-group-vertical>.btn:not(:first-child):not(:last-child){border-radius:0}
.paper .btn-group-vertical>.btn:first-child:not(:last-child){border-top-right-radius:3px;border-top-left-radius:3px;border-bottom-right-radius:0;border-bottom-left-radius:0}
.paper .btn-group-vertical>.btn:last-child:not(:first-child){border-top-right-radius:0;border-top-left-radius:0;border-bottom-right-radius:3px;border-bottom-left-radius:3px}
.paper .btn-group-vertical>.btn-group:not(:first-child):not(:last-child)>.btn{border-radius:0}
.paper .btn-group-vertical>.btn-group:first-child:not(:last-child)>.btn:last-child, .paper .btn-group-vertical>.btn-group:first-child:not(:last-child)>.dropdown-toggle{border-bottom-right-radius:0;border-bottom-left-radius:0}
.paper .btn-group-vertical>.btn-group:last-child:not(:first-child)>.btn:first-child{border-top-right-radius:0;border-top-left-radius:0}
.paper .btn-group-justified{display:table;width:100%;table-layout:fixed;border-collapse:separate}
.paper .btn-group-justified>.btn, .paper .btn-group-justified>.btn-group{float:none;display:table-cell;width:1%}
.paper .btn-group-justified>.btn-group .btn{width:100%}
.paper .btn-group-justified>.btn-group .dropdown-menu{left:auto}
.paper [data-toggle="buttons"]>.btn input[type="radio"], .paper [data-toggle="buttons"]>.btn-group>.btn input[type="radio"], .paper [data-toggle="buttons"]>.btn input[type="checkbox"], .paper [data-toggle="buttons"]>.btn-group>.btn input[type="checkbox"]{position:absolute;clip:rect(0, 0, 0, 0);pointer-events:none}
.paper .input-group{position:relative;display:table;border-collapse:separate}
.paper .input-group[class*="col-"]{float:none;padding-left:0;padding-right:0}
.paper .input-group .form-control{position:relative;z-index:2;float:left;width:100%;margin-bottom:0}
.paper .input-group .form-control:focus{z-index:3}
.paper .input-group-lg>.form-control, .paper .input-group-lg>.input-group-addon, .paper .input-group-lg>.input-group-btn>.btn{height:45px;padding:10px 16px;font-size:17px;line-height:1.3333333;border-radius:3px}
.paper select.input-group-lg>.form-control, .paper select.input-group-lg>.input-group-addon, .paper select.input-group-lg>.input-group-btn>.btn{height:45px;line-height:45px}
.paper textarea.input-group-lg>.form-control, .paper textarea.input-group-lg>.input-group-addon, .paper textarea.input-group-lg>.input-group-btn>.btn, .paper select[multiple].input-group-lg>.form-control, .paper select[multiple].input-group-lg>.input-group-addon, .paper select[multiple].input-group-lg>.input-group-btn>.btn{height:auto}
.paper .input-group-sm>.form-control, .paper .input-group-sm>.input-group-addon, .paper .input-group-sm>.input-group-btn>.btn{height:30px;padding:5px 10px;font-size:12px;line-height:1.5;border-radius:3px}
.paper select.input-group-sm>.form-control, .paper select.input-group-sm>.input-group-addon, .paper select.input-group-sm>.input-group-btn>.btn{height:30px;line-height:30px}
.paper textarea.input-group-sm>.form-control, .paper textarea.input-group-sm>.input-group-addon, .paper textarea.input-group-sm>.input-group-btn>.btn, .paper select[multiple].input-group-sm>.form-control, .paper select[multiple].input-group-sm>.input-group-addon, .paper select[multiple].input-group-sm>.input-group-btn>.btn{height:auto}
.paper .input-group-addon, .paper .input-group-btn, .paper .input-group .form-control{display:table-cell}
.paper .input-group-addon:not(:first-child):not(:last-child), .paper .input-group-btn:not(:first-child):not(:last-child), .paper .input-group .form-control:not(:first-child):not(:last-child){border-radius:0}
.paper .input-group-addon, .paper .input-group-btn{width:1%;white-space:nowrap;vertical-align:middle}
.paper .input-group-addon{padding:6px 16px;font-size:13px;font-weight:normal;line-height:1;color:#666666;text-align:center;background-color:transparent;border:1px solid transparent;border-radius:3px}
.paper .input-group-addon.input-sm{padding:5px 10px;font-size:12px;border-radius:3px}
.paper .input-group-addon.input-lg{padding:10px 16px;font-size:17px;border-radius:3px}
.paper .input-group-addon input[type="radio"], .paper .input-group-addon input[type="checkbox"]{margin-top:0}
.paper .input-group .form-control:first-child, .paper .input-group-addon:first-child, .paper .input-group-btn:first-child>.btn, .paper .input-group-btn:first-child>.btn-group>.btn, .paper .input-group-btn:first-child>.dropdown-toggle, .paper .input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle), .paper .input-group-btn:last-child>.btn-group:not(:last-child)>.btn{border-bottom-right-radius:0;border-top-right-radius:0}
.paper .input-group-addon:first-child{border-right:0}
.paper .input-group .form-control:last-child, .paper .input-group-addon:last-child, .paper .input-group-btn:last-child>.btn, .paper .input-group-btn:last-child>.btn-group>.btn, .paper .input-group-btn:last-child>.dropdown-toggle, .paper .input-group-btn:first-child>.btn:not(:first-child), .paper .input-group-btn:first-child>.btn-group:not(:first-child)>.btn{border-bottom-left-radius:0;border-top-left-radius:0}
.paper .input-group-addon:last-child{border-left:0}
.paper .input-group-btn{position:relative;font-size:0;white-space:nowrap}
.paper .input-group-btn>.btn{position:relative}
.paper .input-group-btn>.btn+.btn{margin-left:-1px}
.paper .input-group-btn>.btn:hover, .paper .input-group-btn>.btn:focus, .paper .input-group-btn>.btn:active{z-index:2}
.paper .input-group-btn:first-child>.btn, .paper .input-group-btn:first-child>.btn-group{margin-right:-1px}
.paper .input-group-btn:last-child>.btn, .paper .input-group-btn:last-child>.btn-group{z-index:2;margin-left:-1px}
.paper .nav{margin-bottom:0;padding-left:0;list-style:none}
.paper .nav>li{position:relative;display:block}
.paper .nav>li>a{position:relative;display:block;padding:10px 15px}
.paper .nav>li>a:hover, .paper .nav>li>a:focus{text-decoration:none;background-color:#eeeeee}
.paper .nav>li.disabled>a{color:#bbbbbb}
.paper .nav>li.disabled>a:hover, .paper .nav>li.disabled>a:focus{color:#bbbbbb;text-decoration:none;background-color:transparent;cursor:not-allowed}
.paper .nav .open>a, .paper .nav .open>a:hover, .paper .nav .open>a:focus{background-color:#eeeeee;border-color:#2196f3}
.paper .nav .nav-divider{height:1px;margin:10.5px 0;overflow:hidden;background-color:#e5e5e5}
.paper .nav>li>a>img{max-width:none}
.paper .nav-tabs{border-bottom:1px solid transparent}
.paper .nav-tabs>li{float:left;margin-bottom:-1px}
.paper .nav-tabs>li>a{margin-right:2px;line-height:1.846;border:1px solid transparent;border-radius:3px 3px 0 0}
.paper .nav-tabs>li>a:hover{border-color:#eeeeee #eeeeee transparent}
.paper .nav-tabs>li.active>a, .paper .nav-tabs>li.active>a:hover, .paper .nav-tabs>li.active>a:focus{color:#666666;background-color:transparent;border:1px solid transparent;border-bottom-color:transparent;cursor:default}
.paper .nav-tabs.nav-justified{width:100%;border-bottom:0}
.paper .nav-tabs.nav-justified>li{float:none}
.paper .nav-tabs.nav-justified>li>a{text-align:center;margin-bottom:5px}
.paper .nav-tabs.nav-justified>.dropdown .dropdown-menu{top:auto;left:auto}
@media (min-width:768px){
.paper .nav-tabs.nav-justified>li{display:table-cell;width:1%}
.paper .nav-tabs.nav-justified>li>a{margin-bottom:0}
}
.paper .nav-tabs.nav-justified>li>a{margin-right:0;border-radius:3px}
.paper .nav-tabs.nav-justified>.active>a, .paper .nav-tabs.nav-justified>.active>a:hover, .paper .nav-tabs.nav-justified>.active>a:focus{border:1px solid transparent}
@media (min-width:768px){
.paper .nav-tabs.nav-justified>li>a{border-bottom:1px solid transparent;border-radius:3px 3px 0 0}
.paper .nav-tabs.nav-justified>.active>a, .paper .nav-tabs.nav-justified>.active>a:hover, .paper .nav-tabs.nav-justified>.active>a:focus{border-bottom-color:#ffffff}
}
.paper .nav-pills>li{float:left}
.paper .nav-pills>li>a{border-radius:3px}
.paper .nav-pills>li+li{margin-left:2px}
.paper .nav-pills>li.active>a, .paper .nav-pills>li.active>a:hover, .paper .nav-pills>li.active>a:focus{color:#ffffff;background-color:#2196f3}
.paper .nav-stacked>li{float:none}
.paper .nav-stacked>li+li{margin-top:2px;margin-left:0}
.paper .nav-justified{width:100%}
.paper .nav-justified>li{float:none}
.paper .nav-justified>li>a{text-align:center;margin-bottom:5px}
.paper .nav-justified>.dropdown .dropdown-menu{top:auto;left:auto}
@media (min-width:768px){
.paper .nav-justified>li{display:table-cell;width:1%}
.paper .nav-justified>li>a{margin-bottom:0}
}
.paper .nav-tabs-justified{border-bottom:0}
.paper .nav-tabs-justified>li>a{margin-right:0;border-radius:3px}
.paper .nav-tabs-justified>.active>a, .paper .nav-tabs-justified>.active>a:hover, .paper .nav-tabs-justified>.active>a:focus{border:1px solid transparent}
@media (min-width:768px){
.paper .nav-tabs-justified>li>a{border-bottom:1px solid transparent;border-radius:3px 3px 0 0}
.paper .nav-tabs-justified>.active>a, .paper .nav-tabs-justified>.active>a:hover, .paper .nav-tabs-justified>.active>a:focus{border-bottom-color:#ffffff}
}
.paper .tab-content>.tab-pane{display:none}
.paper .tab-content>.active{display:block}
.paper .nav-tabs .dropdown-menu{margin-top:-1px;border-top-right-radius:0;border-top-left-radius:0}
.paper .navbar{position:relative;min-height:64px;margin-bottom:23px;border:1px solid transparent}
@media (min-width:768px){
.paper .navbar{border-radius:3px}
}
@media (min-width:768px){
.paper .navbar-header{float:left}
}
.paper .navbar-collapse{overflow-x:visible;padding-right:15px;padding-left:15px;border-top:1px solid transparent;-webkit-box-shadow:inset 0 1px 0 rgba(255,255,255,0.1);box-shadow:inset 0 1px 0 rgba(255,255,255,0.1);-webkit-overflow-scrolling:touch}
.paper .navbar-collapse.in{overflow-y:auto}
@media (min-width:768px){
.paper .navbar-collapse{width:auto;border-top:0;-webkit-box-shadow:none;box-shadow:none}
.paper .navbar-collapse.collapse{display:block !important;height:auto !important;padding-bottom:0;overflow:visible !important}
.paper .navbar-collapse.in{overflow-y:visible}
.paper .navbar-fixed-top .navbar-collapse, .paper .navbar-static-top .navbar-collapse, .paper .navbar-fixed-bottom .navbar-collapse{padding-left:0;padding-right:0}
}
.paper .navbar-fixed-top .navbar-collapse, .paper .navbar-fixed-bottom .navbar-collapse{max-height:340px}
@media (max-device-width:480px) and (orientation:landscape){
.paper .navbar-fixed-top .navbar-collapse, .paper .navbar-fixed-bottom .navbar-collapse{max-height:200px}
}
.paper .container>.navbar-header, .paper .container-fluid>.navbar-header, .paper .container>.navbar-collapse, .paper .container-fluid>.navbar-collapse{margin-right:-15px;margin-left:-15px}
@media (min-width:768px){
.paper .container>.navbar-header, .paper .container-fluid>.navbar-header, .paper .container>.navbar-collapse, .paper .container-fluid>.navbar-collapse{margin-right:0;margin-left:0}
}
.paper .navbar-static-top{z-index:1000;border-width:0 0 1px}
@media (min-width:768px){
.paper .navbar-static-top{border-radius:0}
}
.paper .navbar-fixed-top, .paper .navbar-fixed-bottom{position:fixed;right:0;left:0;z-index:1030}
@media (min-width:768px){
.paper .navbar-fixed-top, .paper .navbar-fixed-bottom{border-radius:0}
}
.paper .navbar-fixed-top{top:0;border-width:0 0 1px}
.paper .navbar-fixed-bottom{bottom:0;margin-bottom:0;border-width:1px 0 0}
.paper .navbar-brand{float:left;padding:20.5px 15px;font-size:17px;line-height:23px;height:64px}
.paper .navbar-brand:hover, .paper .navbar-brand:focus{text-decoration:none}
.paper .navbar-brand>img{display:block}
@media (min-width:768px){
.paper .navbar>.container .navbar-brand, .paper .navbar>.container-fluid .navbar-brand{margin-left:-15px}
}
.paper .navbar-toggle{position:relative;float:right;margin-right:15px;padding:9px 10px;margin-top:15px;margin-bottom:15px;background-color:transparent;background-image:none;border:1px solid transparent;border-radius:3px}
.paper .navbar-toggle:focus{outline:0}
.paper .navbar-toggle .icon-bar{display:block;width:22px;height:2px;border-radius:1px}
.paper .navbar-toggle .icon-bar+.icon-bar{margin-top:4px}
@media (min-width:768px){
.paper .navbar-toggle{display:none}
}
.paper .navbar-nav{margin:10.25px -15px}
.paper .navbar-nav>li>a{padding-top:10px;padding-bottom:10px;line-height:23px}
@media (max-width:767px){
.paper .navbar-nav .open .dropdown-menu{position:static;float:none;width:auto;margin-top:0;background-color:transparent;border:0;-webkit-box-shadow:none;box-shadow:none}
.paper .navbar-nav .open .dropdown-menu>li>a, .paper .navbar-nav .open .dropdown-menu .dropdown-header{padding:5px 15px 5px 25px}
.paper .navbar-nav .open .dropdown-menu>li>a{line-height:23px}
.paper .navbar-nav .open .dropdown-menu>li>a:hover, .paper .navbar-nav .open .dropdown-menu>li>a:focus{background-image:none}
}
@media (min-width:768px){
.paper .navbar-nav{float:left;margin:0}
.paper .navbar-nav>li{float:left}
.paper .navbar-nav>li>a{padding-top:20.5px;padding-bottom:20.5px}
}
.paper .navbar-form{margin-left:-15px;margin-right:-15px;padding:10px 15px;border-top:1px solid transparent;border-bottom:1px solid transparent;-webkit-box-shadow:inset 0 1px 0 rgba(255,255,255,0.1),0 1px 0 rgba(255,255,255,0.1);box-shadow:inset 0 1px 0 rgba(255,255,255,0.1),0 1px 0 rgba(255,255,255,0.1);margin-top:13.5px;margin-bottom:13.5px}
@media (min-width:768px){
.paper .navbar-form .form-group{display:inline-block;margin-bottom:0;vertical-align:middle}
.paper .navbar-form .form-control{display:inline-block;width:auto;vertical-align:middle}
.paper .navbar-form .form-control-static{display:inline-block}
.paper .navbar-form .input-group{display:inline-table;vertical-align:middle}
.paper .navbar-form .input-group .input-group-addon, .paper .navbar-form .input-group .input-group-btn, .paper .navbar-form .input-group .form-control{width:auto}
.paper .navbar-form .input-group>.form-control{width:100%}
.paper .navbar-form .control-label{margin-bottom:0;vertical-align:middle}
.paper .navbar-form .radio, .paper .navbar-form .checkbox{display:inline-block;margin-top:0;margin-bottom:0;vertical-align:middle}
.paper .navbar-form .radio label, .paper .navbar-form .checkbox label{padding-left:0}
.paper .navbar-form .radio input[type="radio"], .paper .navbar-form .checkbox input[type="checkbox"]{position:relative;margin-left:0}
.paper .navbar-form .has-feedback .form-control-feedback{top:0}
}
@media (max-width:767px){
.paper .navbar-form .form-group{margin-bottom:5px}
.paper .navbar-form .form-group:last-child{margin-bottom:0}
}
@media (min-width:768px){
.paper .navbar-form{width:auto;border:0;margin-left:0;margin-right:0;padding-top:0;padding-bottom:0;-webkit-box-shadow:none;box-shadow:none}
}
.paper .navbar-nav>li>.dropdown-menu{margin-top:0;border-top-right-radius:0;border-top-left-radius:0}
.paper .navbar-fixed-bottom .navbar-nav>li>.dropdown-menu{margin-bottom:0;border-top-right-radius:3px;border-top-left-radius:3px;border-bottom-right-radius:0;border-bottom-left-radius:0}
.paper .navbar-btn{margin-top:13.5px;margin-bottom:13.5px}
.paper .navbar-btn.btn-sm{margin-top:17px;margin-bottom:17px}
.paper .navbar-btn.btn-xs{margin-top:21px;margin-bottom:21px}
.paper .navbar-text{margin-top:20.5px;margin-bottom:20.5px}
@media (min-width:768px){
.paper .navbar-text{float:left;margin-left:15px;margin-right:15px}
}
@media (min-width:768px){
.paper .navbar-left{float:left !important}
.paper .navbar-right{float:right !important;margin-right:-15px}
.paper .navbar-right~.navbar-right{margin-right:0}
}
.paper .navbar-default{background-color:#ffffff;border-color:transparent}
.paper .navbar-default .navbar-brand{color:#666666}
.paper .navbar-default .navbar-brand:hover, .paper .navbar-default .navbar-brand:focus{color:#212121;background-color:transparent}
.paper .navbar-default .navbar-text{color:#bbbbbb}
.paper .navbar-default .navbar-nav>li>a{color:#666666}
.paper .navbar-default .navbar-nav>li>a:hover, .paper .navbar-default .navbar-nav>li>a:focus{color:#212121;background-color:transparent}
.paper .navbar-default .navbar-nav>.active>a, .paper .navbar-default .navbar-nav>.active>a:hover, .paper .navbar-default .navbar-nav>.active>a:focus{color:#212121;background-color:#eeeeee}
.paper .navbar-default .navbar-nav>.disabled>a, .paper .navbar-default .navbar-nav>.disabled>a:hover, .paper .navbar-default .navbar-nav>.disabled>a:focus{color:#cccccc;background-color:transparent}
.paper .navbar-default .navbar-toggle{border-color:transparent}
.paper .navbar-default .navbar-toggle:hover, .paper .navbar-default .navbar-toggle:focus{background-color:transparent}
.paper .navbar-default .navbar-toggle .icon-bar{background-color:rgba(0,0,0,0.5)}
.paper .navbar-default .navbar-collapse, .paper .navbar-default .navbar-form{border-color:transparent}
.paper .navbar-default .navbar-nav>.open>a, .paper .navbar-default .navbar-nav>.open>a:hover, .paper .navbar-default .navbar-nav>.open>a:focus{background-color:#eeeeee;color:#212121}
@media (max-width:767px){
.paper .navbar-default .navbar-nav .open .dropdown-menu>li>a{color:#666666}
.paper .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover, .paper .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus{color:#212121;background-color:transparent}
.paper .navbar-default .navbar-nav .open .dropdown-menu>.active>a, .paper .navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover, .paper .navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus{color:#212121;background-color:#eeeeee}
.paper .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a, .paper .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:hover, .paper .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:focus{color:#cccccc;background-color:transparent}
}
.paper .navbar-default .navbar-link{color:#666666}
.paper .navbar-default .navbar-link:hover{color:#212121}
.paper .navbar-default .btn-link{color:#666666}
.paper .navbar-default .btn-link:hover, .paper .navbar-default .btn-link:focus{color:#212121}
.paper .navbar-default .btn-link[disabled]:hover, .paper fieldset[disabled] .navbar-default .btn-link:hover, .paper .navbar-default .btn-link[disabled]:focus, .paper fieldset[disabled] .navbar-default .btn-link:focus{color:#cccccc}
.paper .navbar-inverse{background-color:#2196f3;border-color:transparent}
.paper .navbar-inverse .navbar-brand{color:#b2dbfb}
.paper .navbar-inverse .navbar-brand:hover, .paper .navbar-inverse .navbar-brand:focus{color:#ffffff;background-color:transparent}
.paper .navbar-inverse .navbar-text{color:#bbbbbb}
.paper .navbar-inverse .navbar-nav>li>a{color:#b2dbfb}
.paper .navbar-inverse .navbar-nav>li>a:hover, .paper .navbar-inverse .navbar-nav>li>a:focus{color:#ffffff;background-color:transparent}
.paper .navbar-inverse .navbar-nav>.active>a, .paper .navbar-inverse .navbar-nav>.active>a:hover, .paper .navbar-inverse .navbar-nav>.active>a:focus{color:#ffffff;background-color:#0c7cd5}
.paper .navbar-inverse .navbar-nav>.disabled>a, .paper .navbar-inverse .navbar-nav>.disabled>a:hover, .paper .navbar-inverse .navbar-nav>.disabled>a:focus{color:#444444;background-color:transparent}
.paper .navbar-inverse .navbar-toggle{border-color:transparent}
.paper .navbar-inverse .navbar-toggle:hover, .paper .navbar-inverse .navbar-toggle:focus{background-color:transparent}
.paper .navbar-inverse .navbar-toggle .icon-bar{background-color:rgba(0,0,0,0.5)}
.paper .navbar-inverse .navbar-collapse, .paper .navbar-inverse .navbar-form{border-color:#0c84e4}
.paper .navbar-inverse .navbar-nav>.open>a, .paper .navbar-inverse .navbar-nav>.open>a:hover, .paper .navbar-inverse .navbar-nav>.open>a:focus{background-color:#0c7cd5;color:#ffffff}
@media (max-width:767px){
.paper .navbar-inverse .navbar-nav .open .dropdown-menu>.dropdown-header{border-color:transparent}
.paper .navbar-inverse .navbar-nav .open .dropdown-menu .divider{background-color:transparent}
.paper .navbar-inverse .navbar-nav .open .dropdown-menu>li>a{color:#b2dbfb}
.paper .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover, .paper .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus{color:#ffffff;background-color:transparent}
.paper .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a, .paper .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover, .paper .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus{color:#ffffff;background-color:#0c7cd5}
.paper .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a, .paper .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:hover, .paper .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:focus{color:#444444;background-color:transparent}
}
.paper .navbar-inverse .navbar-link{color:#b2dbfb}
.paper .navbar-inverse .navbar-link:hover{color:#ffffff}
.paper .navbar-inverse .btn-link{color:#b2dbfb}
.paper .navbar-inverse .btn-link:hover, .paper .navbar-inverse .btn-link:focus{color:#ffffff}
.paper .navbar-inverse .btn-link[disabled]:hover, .paper fieldset[disabled] .navbar-inverse .btn-link:hover, .paper .navbar-inverse .btn-link[disabled]:focus, .paper fieldset[disabled] .navbar-inverse .btn-link:focus{color:#444444}
.paper .breadcrumb{padding:8px 15px;margin-bottom:23px;list-style:none;background-color:#f5f5f5;border-radius:3px}
.paper .breadcrumb>li{display:inline-block}
.paper .breadcrumb>li+li:before{content:"/\00a0";padding:0 5px;color:#cccccc}
.paper .breadcrumb>.active{color:#bbbbbb}
.paper .pagination{display:inline-block;padding-left:0;margin:23px 0;border-radius:3px}
.paper .pagination>li{display:inline}
.paper .pagination>li>a, .paper .pagination>li>span{position:relative;float:left;padding:6px 16px;line-height:1.846;text-decoration:none;color:#2196f3;background-color:#ffffff;border:1px solid #dddddd;margin-left:-1px}
.paper .pagination>li:first-child>a, .paper .pagination>li:first-child>span{margin-left:0;border-bottom-left-radius:3px;border-top-left-radius:3px}
.paper .pagination>li:last-child>a, .paper .pagination>li:last-child>span{border-bottom-right-radius:3px;border-top-right-radius:3px}
.paper .pagination>li>a:hover, .paper .pagination>li>span:hover, .paper .pagination>li>a:focus, .paper .pagination>li>span:focus{z-index:2;color:#0a6ebd;background-color:#eeeeee;border-color:#dddddd}
.paper .pagination>.active>a, .paper .pagination>.active>span, .paper .pagination>.active>a:hover, .paper .pagination>.active>span:hover, .paper .pagination>.active>a:focus, .paper .pagination>.active>span:focus{z-index:3;color:#ffffff;background-color:#2196f3;border-color:#2196f3;cursor:default}
.paper .pagination>.disabled>span, .paper .pagination>.disabled>span:hover, .paper .pagination>.disabled>span:focus, .paper .pagination>.disabled>a, .paper .pagination>.disabled>a:hover, .paper .pagination>.disabled>a:focus{color:#bbbbbb;background-color:#ffffff;border-color:#dddddd;cursor:not-allowed}
.paper .pagination-lg>li>a, .paper .pagination-lg>li>span{padding:10px 16px;font-size:17px;line-height:1.3333333}
.paper .pagination-lg>li:first-child>a, .paper .pagination-lg>li:first-child>span{border-bottom-left-radius:3px;border-top-left-radius:3px}
.paper .pagination-lg>li:last-child>a, .paper .pagination-lg>li:last-child>span{border-bottom-right-radius:3px;border-top-right-radius:3px}
.paper .pagination-sm>li>a, .paper .pagination-sm>li>span{padding:5px 10px;font-size:12px;line-height:1.5}
.paper .pagination-sm>li:first-child>a, .paper .pagination-sm>li:first-child>span{border-bottom-left-radius:3px;border-top-left-radius:3px}
.paper .pagination-sm>li:last-child>a, .paper .pagination-sm>li:last-child>span{border-bottom-right-radius:3px;border-top-right-radius:3px}
.paper .pager{padding-left:0;margin:23px 0;list-style:none;text-align:center}
.paper .pager li{display:inline}
.paper .pager li>a, .paper .pager li>span{display:inline-block;padding:5px 14px;background-color:#ffffff;border:1px solid #dddddd;border-radius:15px}
.paper .pager li>a:hover, .paper .pager li>a:focus{text-decoration:none;background-color:#eeeeee}
.paper .pager .next>a, .paper .pager .next>span{float:right}
.paper .pager .previous>a, .paper .pager .previous>span{float:left}
.paper .pager .disabled>a, .paper .pager .disabled>a:hover, .paper .pager .disabled>a:focus, .paper .pager .disabled>span{color:#bbbbbb;background-color:#ffffff;cursor:not-allowed}
.paper .label{display:inline;padding:.2em .6em .3em;font-size:75%;font-weight:bold;line-height:1;color:#ffffff;text-align:center;white-space:nowrap;vertical-align:baseline;border-radius:.25em}
.paper a.label:hover, .paper a.label:focus{color:#ffffff;text-decoration:none;cursor:pointer}
.paper .label:empty{display:none}
.paper .btn .label{position:relative;top:-1px}
.paper .label-default{background-color:#bbbbbb}
.paper .label-default[href]:hover, .paper .label-default[href]:focus{background-color:#a2a2a2}
.paper .label-primary{background-color:#2196f3}
.paper .label-primary[href]:hover, .paper .label-primary[href]:focus{background-color:#0c7cd5}
.paper .label-success{background-color:#4caf50}
.paper .label-success[href]:hover, .paper .label-success[href]:focus{background-color:#3d8b40}
.paper .label-info{background-color:#9c27b0}
.paper .label-info[href]:hover, .paper .label-info[href]:focus{background-color:#771e86}
.paper .label-warning{background-color:#ff9800}
.paper .label-warning[href]:hover, .paper .label-warning[href]:focus{background-color:#cc7a00}
.paper .label-danger{background-color:#e51c23}
.paper .label-danger[href]:hover, .paper .label-danger[href]:focus{background-color:#b9151b}
.paper .badge{display:inline-block;min-width:10px;padding:3px 7px;font-size:12px;font-weight:normal;color:#ffffff;line-height:1;vertical-align:middle;white-space:nowrap;text-align:center;background-color:#bbbbbb;border-radius:10px}
.paper .badge:empty{display:none}
.paper .btn .badge{position:relative;top:-1px}
.paper .btn-xs .badge, .paper .btn-group-xs>.btn .badge{top:0;padding:1px 5px}
.paper a.badge:hover, .paper a.badge:focus{color:#ffffff;text-decoration:none;cursor:pointer}
.paper .list-group-item.active>.badge, .paper .nav-pills>.active>a>.badge{color:#2196f3;background-color:#ffffff}
.paper .list-group-item>.badge{float:right}
.paper .list-group-item>.badge+.badge{margin-right:5px}
.paper .nav-pills>li>a>.badge{margin-left:3px}
.paper .jumbotron{padding-top:30px;padding-bottom:30px;margin-bottom:30px;color:inherit;background-color:#f5f5f5}
.paper .jumbotron h1, .paper .jumbotron .h1{color:#444444}
.paper .jumbotron p{margin-bottom:15px;font-size:20px;font-weight:200}
.paper .jumbotron>hr{border-top-color:#dcdcdc}
.paper .container .jumbotron, .paper .container-fluid .jumbotron{border-radius:3px;padding-left:15px;padding-right:15px}
.paper .jumbotron .container{max-width:100%}
@media screen and (min-width:768px){
.paper .jumbotron{padding-top:48px;padding-bottom:48px}
.paper .container .jumbotron, .paper .container-fluid .jumbotron{padding-left:60px;padding-right:60px}
.paper .jumbotron h1, .paper .jumbotron .h1{font-size:59px}
}
.paper .thumbnail{display:block;padding:4px;margin-bottom:23px;line-height:1.846;background-color:#ffffff;border:1px solid #dddddd;border-radius:3px;-webkit-transition:border .2s ease-in-out;-o-transition:border .2s ease-in-out;transition:border .2s ease-in-out}
.paper .thumbnail>img, .paper .thumbnail a>img{margin-left:auto;margin-right:auto}
.paper a.thumbnail:hover, .paper a.thumbnail:focus, .paper a.thumbnail.active{border-color:#2196f3}
.paper .thumbnail .caption{padding:9px;color:#666666}
.paper .alert{padding:15px;margin-bottom:23px;border:1px solid transparent;border-radius:3px}
.paper .alert h4{margin-top:0;color:inherit}
.paper .alert .alert-link{font-weight:bold}
.paper .alert>p, .paper .alert>ul{margin-bottom:0}
.paper .alert>p+p{margin-top:5px}
.paper .alert-dismissable, .paper .alert-dismissible{padding-right:35px}
.paper .alert-dismissable .close, .paper .alert-dismissible .close{position:relative;top:-2px;right:-21px;color:inherit}
.paper .alert-success{background-color:#4caf50;border-color:#509d44;color:#ffffff}
.paper .alert-success hr{border-top-color:#478b3d}
.paper .alert-success .alert-link{color:#e6e6e6}
.paper .alert-info{background-color:#9c27b0;border-color:#75229b;color:#ffffff}
.paper .alert-info hr{border-top-color:#661e86}
.paper .alert-info .alert-link{color:#e6e6e6}
.paper .alert-warning{background-color:#ff9800;border-color:#e66300;color:#ffffff}
.paper .alert-warning hr{border-top-color:#cc5800}
.paper .alert-warning .alert-link{color:#e6e6e6}
.paper .alert-danger{background-color:#e51c23;border-color:#d0183d;color:#ffffff}
.paper .alert-danger hr{border-top-color:#b91536}
.paper .alert-danger .alert-link{color:#e6e6e6}
@-webkit-keyframes progress-bar-stripes{from{background-position:40px 0}to{background-position:0 0}}
@-o-keyframes progress-bar-stripes{from{background-position:40px 0}to{background-position:0 0}}
@keyframes progress-bar-stripes{from{background-position:40px 0}to{background-position:0 0}}
.paper .progress{overflow:hidden;height:23px;margin-bottom:23px;background-color:#f5f5f5;border-radius:3px;-webkit-box-shadow:inset 0 1px 2px rgba(0,0,0,0.1);box-shadow:inset 0 1px 2px rgba(0,0,0,0.1)}
.paper .progress-bar{float:left;width:0%;height:100%;font-size:12px;line-height:23px;color:#ffffff;text-align:center;background-color:#2196f3;-webkit-box-shadow:inset 0 -1px 0 rgba(0,0,0,0.15);box-shadow:inset 0 -1px 0 rgba(0,0,0,0.15);-webkit-transition:width 0.6s ease;-o-transition:width 0.6s ease;transition:width 0.6s ease}
.paper .progress-striped .progress-bar, .paper .progress-bar-striped{background-image:-webkit-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);background-image:-o-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);background-image:linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);-webkit-background-size:40px 40px;background-size:40px 40px}
.paper .progress.active .progress-bar, .paper .progress-bar.active{-webkit-animation:progress-bar-stripes 2s linear infinite;-o-animation:progress-bar-stripes 2s linear infinite;animation:progress-bar-stripes 2s linear infinite}
.paper .progress-bar-success{background-color:#4caf50}
.paper .progress-striped .progress-bar-success{background-image:-webkit-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);background-image:-o-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);background-image:linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent)}
.paper .progress-bar-info{background-color:#9c27b0}
.paper .progress-striped .progress-bar-info{background-image:-webkit-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);background-image:-o-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);background-image:linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent)}
.paper .progress-bar-warning{background-color:#ff9800}
.paper .progress-striped .progress-bar-warning{background-image:-webkit-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);background-image:-o-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);background-image:linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent)}
.paper .progress-bar-danger{background-color:#e51c23}
.paper .progress-striped .progress-bar-danger{background-image:-webkit-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);background-image:-o-linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);background-image:linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent)}
.paper .media{margin-top:15px}
.paper .media:first-child{margin-top:0}
.paper .media, .paper .media-body{zoom:1;overflow:hidden}
.paper .media-body{width:10000px}
.paper .media-object{display:block}
.paper .media-object.img-thumbnail{max-width:none}
.paper .media-right, .paper .media>.pull-right{padding-left:10px}
.paper .media-left, .paper .media>.pull-left{padding-right:10px}
.paper .media-left, .paper .media-right, .paper .media-body{display:table-cell;vertical-align:top}
.paper .media-middle{vertical-align:middle}
.paper .media-bottom{vertical-align:bottom}
.paper .media-heading{margin-top:0;margin-bottom:5px}
.paper .media-list{padding-left:0;list-style:none}
.paper .list-group{margin-bottom:20px;padding-left:0}
.paper .list-group-item{position:relative;display:block;padding:10px 15px;margin-bottom:-1px;background-color:#ffffff;border:1px solid #dddddd}
.paper .list-group-item:first-child{border-top-right-radius:3px;border-top-left-radius:3px}
.paper .list-group-item:last-child{margin-bottom:0;border-bottom-right-radius:3px;border-bottom-left-radius:3px}
.paper a.list-group-item, .paper button.list-group-item{color:#555555}
.paper a.list-group-item .list-group-item-heading, .paper button.list-group-item .list-group-item-heading{color:#333333}
.paper a.list-group-item:hover, .paper button.list-group-item:hover, .paper a.list-group-item:focus, .paper button.list-group-item:focus{text-decoration:none;color:#555555;background-color:#f5f5f5}
.paper button.list-group-item{width:100%;text-align:left}
.paper .list-group-item.disabled, .paper .list-group-item.disabled:hover, .paper .list-group-item.disabled:focus{background-color:#eeeeee;color:#bbbbbb;cursor:not-allowed}
.paper .list-group-item.disabled .list-group-item-heading, .paper .list-group-item.disabled:hover .list-group-item-heading, .paper .list-group-item.disabled:focus .list-group-item-heading{color:inherit}
.paper .list-group-item.disabled .list-group-item-text, .paper .list-group-item.disabled:hover .list-group-item-text, .paper .list-group-item.disabled:focus .list-group-item-text{color:#bbbbbb}
.paper .list-group-item.active, .paper .list-group-item.active:hover, .paper .list-group-item.active:focus{z-index:2;color:#ffffff;background-color:#2196f3;border-color:#2196f3}
.paper .list-group-item.active .list-group-item-heading, .paper .list-group-item.active:hover .list-group-item-heading, .paper .list-group-item.active:focus .list-group-item-heading, .paper .list-group-item.active .list-group-item-heading>small, .paper .list-group-item.active:hover .list-group-item-heading>small, .paper .list-group-item.active:focus .list-group-item-heading>small, .paper .list-group-item.active .list-group-item-heading>.small, .paper .list-group-item.active:hover .list-group-item-heading>.small, .paper .list-group-item.active:focus .list-group-item-heading>.small{color:inherit}
.paper .list-group-item.active .list-group-item-text, .paper .list-group-item.active:hover .list-group-item-text, .paper .list-group-item.active:focus .list-group-item-text{color:#e3f2fd}
.paper .list-group-item-success{color:#4caf50;background-color:#dff0d8}
.paper a.list-group-item-success, .paper button.list-group-item-success{color:#4caf50}
.paper a.list-group-item-success .list-group-item-heading, .paper button.list-group-item-success .list-group-item-heading{color:inherit}
.paper a.list-group-item-success:hover, .paper button.list-group-item-success:hover, .paper a.list-group-item-success:focus, .paper button.list-group-item-success:focus{color:#4caf50;background-color:#d0e9c6}
.paper a.list-group-item-success.active, .paper button.list-group-item-success.active, .paper a.list-group-item-success.active:hover, .paper button.list-group-item-success.active:hover, .paper a.list-group-item-success.active:focus, .paper button.list-group-item-success.active:focus{color:#fff;background-color:#4caf50;border-color:#4caf50}
.paper .list-group-item-info{color:#9c27b0;background-color:#e1bee7}
.paper a.list-group-item-info, .paper button.list-group-item-info{color:#9c27b0}
.paper a.list-group-item-info .list-group-item-heading, .paper button.list-group-item-info .list-group-item-heading{color:inherit}
.paper a.list-group-item-info:hover, .paper button.list-group-item-info:hover, .paper a.list-group-item-info:focus, .paper button.list-group-item-info:focus{color:#9c27b0;background-color:#d8abe0}
.paper a.list-group-item-info.active, .paper button.list-group-item-info.active, .paper a.list-group-item-info.active:hover, .paper button.list-group-item-info.active:hover, .paper a.list-group-item-info.active:focus, .paper button.list-group-item-info.active:focus{color:#fff;background-color:#9c27b0;border-color:#9c27b0}
.paper .list-group-item-warning{color:#ff9800;background-color:#ffe0b2}
.paper a.list-group-item-warning, .paper button.list-group-item-warning{color:#ff9800}
.paper a.list-group-item-warning .list-group-item-heading, .paper button.list-group-item-warning .list-group-item-heading{color:inherit}
.paper a.list-group-item-warning:hover, .paper button.list-group-item-warning:hover, .paper a.list-group-item-warning:focus, .paper button.list-group-item-warning:focus{color:#ff9800;background-color:#ffd699}
.paper a.list-group-item-warning.active, .paper button.list-group-item-warning.active, .paper a.list-group-item-warning.active:hover, .paper button.list-group-item-warning.active:hover, .paper a.list-group-item-warning.active:focus, .paper button.list-group-item-warning.active:focus{color:#fff;background-color:#ff9800;border-color:#ff9800}
.paper .list-group-item-danger{color:#e51c23;background-color:#f9bdbb}
.paper a.list-group-item-danger, .paper button.list-group-item-danger{color:#e51c23}
.paper a.list-group-item-danger .list-group-item-heading, .paper button.list-group-item-danger .list-group-item-heading{color:inherit}
.paper a.list-group-item-danger:hover, .paper button.list-group-item-danger:hover, .paper a.list-group-item-danger:focus, .paper button.list-group-item-danger:focus{color:#e51c23;background-color:#f7a6a4}
.paper a.list-group-item-danger.active, .paper button.list-group-item-danger.active, .paper a.list-group-item-danger.active:hover, .paper button.list-group-item-danger.active:hover, .paper a.list-group-item-danger.active:focus, .paper button.list-group-item-danger.active:focus{color:#fff;background-color:#e51c23;border-color:#e51c23}
.paper .list-group-item-heading{margin-top:0;margin-bottom:5px}
.paper .list-group-item-text{margin-bottom:0;line-height:1.3}
.paper .panel{margin-bottom:23px;background-color:#ffffff;border:1px solid transparent;border-radius:3px;-webkit-box-shadow:0 1px 1px rgba(0,0,0,0.05);box-shadow:0 1px 1px rgba(0,0,0,0.05)}
.paper .panel-body{padding:15px}
.paper .panel-heading{padding:10px 15px;border-bottom:1px solid transparent;border-top-right-radius:2px;border-top-left-radius:2px}
.paper .panel-heading>.dropdown .dropdown-toggle{color:inherit}
.paper .panel-title{margin-top:0;margin-bottom:0;font-size:15px;color:inherit}
.paper .panel-title>a, .paper .panel-title>small, .paper .panel-title>.small, .paper .panel-title>small>a, .paper .panel-title>.small>a{color:inherit}
.paper .panel-footer{padding:10px 15px;background-color:#f5f5f5;border-top:1px solid #dddddd;border-bottom-right-radius:2px;border-bottom-left-radius:2px}
.paper .panel>.list-group, .paper .panel>.panel-collapse>.list-group{margin-bottom:0}
.paper .panel>.list-group .list-group-item, .paper .panel>.panel-collapse>.list-group .list-group-item{border-width:1px 0;border-radius:0}
.paper .panel>.list-group:first-child .list-group-item:first-child, .paper .panel>.panel-collapse>.list-group:first-child .list-group-item:first-child{border-top:0;border-top-right-radius:2px;border-top-left-radius:2px}
.paper .panel>.list-group:last-child .list-group-item:last-child, .paper .panel>.panel-collapse>.list-group:last-child .list-group-item:last-child{border-bottom:0;border-bottom-right-radius:2px;border-bottom-left-radius:2px}
.paper .panel>.panel-heading+.panel-collapse>.list-group .list-group-item:first-child{border-top-right-radius:0;border-top-left-radius:0}
.paper .panel-heading+.list-group .list-group-item:first-child{border-top-width:0}
.paper .list-group+.panel-footer{border-top-width:0}
.paper .panel>.table, .paper .panel>.table-responsive>.table, .paper .panel>.panel-collapse>.table{margin-bottom:0}
.paper .panel>.table caption, .paper .panel>.table-responsive>.table caption, .paper .panel>.panel-collapse>.table caption{padding-left:15px;padding-right:15px}
.paper .panel>.table:first-child, .paper .panel>.table-responsive:first-child>.table:first-child{border-top-right-radius:2px;border-top-left-radius:2px}
.paper .panel>.table:first-child>thead:first-child>tr:first-child, .paper .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child, .paper .panel>.table:first-child>tbody:first-child>tr:first-child, .paper .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child{border-top-left-radius:2px;border-top-right-radius:2px}
.paper .panel>.table:first-child>thead:first-child>tr:first-child td:first-child, .paper .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:first-child, .paper .panel>.table:first-child>tbody:first-child>tr:first-child td:first-child, .paper .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:first-child, .paper .panel>.table:first-child>thead:first-child>tr:first-child th:first-child, .paper .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:first-child, .paper .panel>.table:first-child>tbody:first-child>tr:first-child th:first-child, .paper .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:first-child{border-top-left-radius:2px}
.paper .panel>.table:first-child>thead:first-child>tr:first-child td:last-child, .paper .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:last-child, .paper .panel>.table:first-child>tbody:first-child>tr:first-child td:last-child, .paper .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:last-child, .paper .panel>.table:first-child>thead:first-child>tr:first-child th:last-child, .paper .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:last-child, .paper .panel>.table:first-child>tbody:first-child>tr:first-child th:last-child, .paper .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:last-child{border-top-right-radius:2px}
.paper .panel>.table:last-child, .paper .panel>.table-responsive:last-child>.table:last-child{border-bottom-right-radius:2px;border-bottom-left-radius:2px}
.paper .panel>.table:last-child>tbody:last-child>tr:last-child, .paper .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child, .paper .panel>.table:last-child>tfoot:last-child>tr:last-child, .paper .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child{border-bottom-left-radius:2px;border-bottom-right-radius:2px}
.paper .panel>.table:last-child>tbody:last-child>tr:last-child td:first-child, .paper .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:first-child, .paper .panel>.table:last-child>tfoot:last-child>tr:last-child td:first-child, .paper .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:first-child, .paper .panel>.table:last-child>tbody:last-child>tr:last-child th:first-child, .paper .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:first-child, .paper .panel>.table:last-child>tfoot:last-child>tr:last-child th:first-child, .paper .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:first-child{border-bottom-left-radius:2px}
.paper .panel>.table:last-child>tbody:last-child>tr:last-child td:last-child, .paper .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:last-child, .paper .panel>.table:last-child>tfoot:last-child>tr:last-child td:last-child, .paper .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:last-child, .paper .panel>.table:last-child>tbody:last-child>tr:last-child th:last-child, .paper .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:last-child, .paper .panel>.table:last-child>tfoot:last-child>tr:last-child th:last-child, .paper .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:last-child{border-bottom-right-radius:2px}
.paper .panel>.panel-body+.table, .paper .panel>.panel-body+.table-responsive, .paper .panel>.table+.panel-body, .paper .panel>.table-responsive+.panel-body{border-top:1px solid #dddddd}
.paper .panel>.table>tbody:first-child>tr:first-child th, .paper .panel>.table>tbody:first-child>tr:first-child td{border-top:0}
.paper .panel>.table-bordered, .paper .panel>.table-responsive>.table-bordered{border:0}
.paper .panel>.table-bordered>thead>tr>th:first-child, .paper .panel>.table-responsive>.table-bordered>thead>tr>th:first-child, .paper .panel>.table-bordered>tbody>tr>th:first-child, .paper .panel>.table-responsive>.table-bordered>tbody>tr>th:first-child, .paper .panel>.table-bordered>tfoot>tr>th:first-child, .paper .panel>.table-responsive>.table-bordered>tfoot>tr>th:first-child, .paper .panel>.table-bordered>thead>tr>td:first-child, .paper .panel>.table-responsive>.table-bordered>thead>tr>td:first-child, .paper .panel>.table-bordered>tbody>tr>td:first-child, .paper .panel>.table-responsive>.table-bordered>tbody>tr>td:first-child, .paper .panel>.table-bordered>tfoot>tr>td:first-child, .paper .panel>.table-responsive>.table-bordered>tfoot>tr>td:first-child{border-left:0}
.paper .panel>.table-bordered>thead>tr>th:last-child, .paper .panel>.table-responsive>.table-bordered>thead>tr>th:last-child, .paper .panel>.table-bordered>tbody>tr>th:last-child, .paper .panel>.table-responsive>.table-bordered>tbody>tr>th:last-child, .paper .panel>.table-bordered>tfoot>tr>th:last-child, .paper .panel>.table-responsive>.table-bordered>tfoot>tr>th:last-child, .paper .panel>.table-bordered>thead>tr>td:last-child, .paper .panel>.table-responsive>.table-bordered>thead>tr>td:last-child, .paper .panel>.table-bordered>tbody>tr>td:last-child, .paper .panel>.table-responsive>.table-bordered>tbody>tr>td:last-child, .paper .panel>.table-bordered>tfoot>tr>td:last-child, .paper .panel>.table-responsive>.table-bordered>tfoot>tr>td:last-child{border-right:0}
.paper .panel>.table-bordered>thead>tr:first-child>td, .paper .panel>.table-responsive>.table-bordered>thead>tr:first-child>td, .paper .panel>.table-bordered>tbody>tr:first-child>td, .paper .panel>.table-responsive>.table-bordered>tbody>tr:first-child>td, .paper .panel>.table-bordered>thead>tr:first-child>th, .paper .panel>.table-responsive>.table-bordered>thead>tr:first-child>th, .paper .panel>.table-bordered>tbody>tr:first-child>th, .paper .panel>.table-responsive>.table-bordered>tbody>tr:first-child>th{border-bottom:0}
.paper .panel>.table-bordered>tbody>tr:last-child>td, .paper .panel>.table-responsive>.table-bordered>tbody>tr:last-child>td, .paper .panel>.table-bordered>tfoot>tr:last-child>td, .paper .panel>.table-responsive>.table-bordered>tfoot>tr:last-child>td, .paper .panel>.table-bordered>tbody>tr:last-child>th, .paper .panel>.table-responsive>.table-bordered>tbody>tr:last-child>th, .paper .panel>.table-bordered>tfoot>tr:last-child>th, .paper .panel>.table-responsive>.table-bordered>tfoot>tr:last-child>th{border-bottom:0}
.paper .panel>.table-responsive{border:0;margin-bottom:0}
.paper .panel-group{margin-bottom:23px}
.paper .panel-group .panel{margin-bottom:0;border-radius:3px}
.paper .panel-group .panel+.panel{margin-top:5px}
.paper .panel-group .panel-heading{border-bottom:0}
.paper .panel-group .panel-heading+.panel-collapse>.panel-body, .paper .panel-group .panel-heading+.panel-collapse>.list-group{border-top:1px solid #dddddd}
.paper .panel-group .panel-footer{border-top:0}
.paper .panel-group .panel-footer+.panel-collapse .panel-body{border-bottom:1px solid #dddddd}
.paper .panel-default{border-color:#dddddd}
.paper .panel-default>.panel-heading{color:#212121;background-color:#f5f5f5;border-color:#dddddd}
.paper .panel-default>.panel-heading+.panel-collapse>.panel-body{border-top-color:#dddddd}
.paper .panel-default>.panel-heading .badge{color:#f5f5f5;background-color:#212121}
.paper .panel-default>.panel-footer+.panel-collapse>.panel-body{border-bottom-color:#dddddd}
.paper .panel-primary{border-color:#2196f3}
.paper .panel-primary>.panel-heading{color:#ffffff;background-color:#2196f3;border-color:#2196f3}
.paper .panel-primary>.panel-heading+.panel-collapse>.panel-body{border-top-color:#2196f3}
.paper .panel-primary>.panel-heading .badge{color:#2196f3;background-color:#ffffff}
.paper .panel-primary>.panel-footer+.panel-collapse>.panel-body{border-bottom-color:#2196f3}
.paper .panel-success{border-color:#d6e9c6}
.paper .panel-success>.panel-heading{color:#ffffff;background-color:#4caf50;border-color:#d6e9c6}
.paper .panel-success>.panel-heading+.panel-collapse>.panel-body{border-top-color:#d6e9c6}
.paper .panel-success>.panel-heading .badge{color:#4caf50;background-color:#ffffff}
.paper .panel-success>.panel-footer+.panel-collapse>.panel-body{border-bottom-color:#d6e9c6}
.paper .panel-info{border-color:#cba4dd}
.paper .panel-info>.panel-heading{color:#ffffff;background-color:#9c27b0;border-color:#cba4dd}
.paper .panel-info>.panel-heading+.panel-collapse>.panel-body{border-top-color:#cba4dd}
.paper .panel-info>.panel-heading .badge{color:#9c27b0;background-color:#ffffff}
.paper .panel-info>.panel-footer+.panel-collapse>.panel-body{border-bottom-color:#cba4dd}
.paper .panel-warning{border-color:#ffc599}
.paper .panel-warning>.panel-heading{color:#ffffff;background-color:#ff9800;border-color:#ffc599}
.paper .panel-warning>.panel-heading+.panel-collapse>.panel-body{border-top-color:#ffc599}
.paper .panel-warning>.panel-heading .badge{color:#ff9800;background-color:#ffffff}
.paper .panel-warning>.panel-footer+.panel-collapse>.panel-body{border-bottom-color:#ffc599}
.paper .panel-danger{border-color:#f7a4af}
.paper .panel-danger>.panel-heading{color:#ffffff;background-color:#e51c23;border-color:#f7a4af}
.paper .panel-danger>.panel-heading+.panel-collapse>.panel-body{border-top-color:#f7a4af}
.paper .panel-danger>.panel-heading .badge{color:#e51c23;background-color:#ffffff}
.paper .panel-danger>.panel-footer+.panel-collapse>.panel-body{border-bottom-color:#f7a4af}
.paper .embed-responsive{position:relative;display:block;height:0;padding:0;overflow:hidden}
.paper .embed-responsive .embed-responsive-item, .paper .embed-responsive iframe, .paper .embed-responsive embed, .paper .embed-responsive object, .paper .embed-responsive video{position:absolute;top:0;left:0;bottom:0;height:100%;width:100%;border:0}
.paper .embed-responsive-16by9{padding-bottom:56.25%}
.paper .embed-responsive-4by3{padding-bottom:75%}
.paper .well{min-height:20px;padding:19px;margin-bottom:20px;background-color:#f5f5f5;border:1px solid transparent;border-radius:3px;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.05);box-shadow:inset 0 1px 1px rgba(0,0,0,0.05)}
.paper .well blockquote{border-color:#ddd;border-color:rgba(0,0,0,0.15)}
.paper .well-lg{padding:24px;border-radius:3px}
.paper .well-sm{padding:9px;border-radius:3px}
.paper .close{float:right;font-size:19.5px;font-weight:normal;line-height:1;color:#000000;text-shadow:none;opacity:0.2;filter:alpha(opacity=20)}
.paper .close:hover, .paper .close:focus{color:#000000;text-decoration:none;cursor:pointer;opacity:0.5;filter:alpha(opacity=50)}
.paper button.close{padding:0;cursor:pointer;background:transparent;border:0;-webkit-appearance:none}
.paper .modal-open{overflow:hidden}
.paper .modal{display:none;overflow:hidden;position:fixed;top:0;right:0;bottom:0;left:0;z-index:1050;-webkit-overflow-scrolling:touch;outline:0}
.paper .modal.fade .modal-dialog{-webkit-transform:translate(0, -25%);-ms-transform:translate(0, -25%);-o-transform:translate(0, -25%);transform:translate(0, -25%);-webkit-transition:-webkit-transform .3s ease-out;-o-transition:-o-transform .3s ease-out;transition:transform .3s ease-out}
.paper .modal.in .modal-dialog{-webkit-transform:translate(0, 0);-ms-transform:translate(0, 0);-o-transform:translate(0, 0);transform:translate(0, 0)}
.paper .modal-open .modal{overflow-x:hidden;overflow-y:auto}
.paper .modal-dialog{position:relative;width:auto;margin:10px}
.paper .modal-content{position:relative;background-color:#ffffff;border:1px solid #999999;border:1px solid transparent;border-radius:3px;-webkit-box-shadow:0 3px 9px rgba(0,0,0,0.5);box-shadow:0 3px 9px rgba(0,0,0,0.5);-webkit-background-clip:padding-box;background-clip:padding-box;outline:0}
.paper .modal-backdrop{position:fixed;top:0;right:0;bottom:0;left:0;z-index:1040;background-color:#000000}
.paper .modal-backdrop.fade{opacity:0;filter:alpha(opacity=0)}
.paper .modal-backdrop.in{opacity:0.5;filter:alpha(opacity=50)}
.paper .modal-header{padding:15px;border-bottom:1px solid transparent}
.paper .modal-header .close{margin-top:-2px}
.paper .modal-title{margin:0;line-height:1.846}
.paper .modal-body{position:relative;padding:15px}
.paper .modal-footer{padding:15px;text-align:right;border-top:1px solid transparent}
.paper .modal-footer .btn+.btn{margin-left:5px;margin-bottom:0}
.paper .modal-footer .btn-group .btn+.btn{margin-left:-1px}
.paper .modal-footer .btn-block+.btn-block{margin-left:0}
.paper .modal-scrollbar-measure{position:absolute;top:-9999px;width:50px;height:50px;overflow:scroll}
@media (min-width:768px){
.paper .modal-dialog{width:600px;margin:30px auto}
.paper .modal-content{-webkit-box-shadow:0 5px 15px rgba(0,0,0,0.5);box-shadow:0 5px 15px rgba(0,0,0,0.5)}
.paper .modal-sm{width:300px}
}
@media (min-width:992px){
.paper .modal-lg{width:900px}
}
.paper .tooltip{position:absolute;z-index:1070;display:block;font-family:"Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;font-style:normal;font-weight:normal;letter-spacing:normal;line-break:auto;line-height:1.846;text-align:left;text-align:start;text-decoration:none;text-shadow:none;text-transform:none;white-space:normal;word-break:normal;word-spacing:normal;word-wrap:normal;font-size:12px;opacity:0;filter:alpha(opacity=0)}
.paper .tooltip.in{opacity:0.9;filter:alpha(opacity=90)}
.paper .tooltip.top{margin-top:-3px;padding:5px 0}
.paper .tooltip.right{margin-left:3px;padding:0 5px}
.paper .tooltip.bottom{margin-top:3px;padding:5px 0}
.paper .tooltip.left{margin-left:-3px;padding:0 5px}
.paper .tooltip-inner{max-width:200px;padding:3px 8px;color:#ffffff;text-align:center;background-color:#727272;border-radius:3px}
.paper .tooltip-arrow{position:absolute;width:0;height:0;border-color:transparent;border-style:solid}
.paper .tooltip.top .tooltip-arrow{bottom:0;left:50%;margin-left:-5px;border-width:5px 5px 0;border-top-color:#727272}
.paper .tooltip.top-left .tooltip-arrow{bottom:0;right:5px;margin-bottom:-5px;border-width:5px 5px 0;border-top-color:#727272}
.paper .tooltip.top-right .tooltip-arrow{bottom:0;left:5px;margin-bottom:-5px;border-width:5px 5px 0;border-top-color:#727272}
.paper .tooltip.right .tooltip-arrow{top:50%;left:0;margin-top:-5px;border-width:5px 5px 5px 0;border-right-color:#727272}
.paper .tooltip.left .tooltip-arrow{top:50%;right:0;margin-top:-5px;border-width:5px 0 5px 5px;border-left-color:#727272}
.paper .tooltip.bottom .tooltip-arrow{top:0;left:50%;margin-left:-5px;border-width:0 5px 5px;border-bottom-color:#727272}
.paper .tooltip.bottom-left .tooltip-arrow{top:0;right:5px;margin-top:-5px;border-width:0 5px 5px;border-bottom-color:#727272}
.paper .tooltip.bottom-right .tooltip-arrow{top:0;left:5px;margin-top:-5px;border-width:0 5px 5px;border-bottom-color:#727272}
.paper .popover{position:absolute;top:0;left:0;z-index:1060;display:none;max-width:276px;padding:1px;font-family:"Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;font-style:normal;font-weight:normal;letter-spacing:normal;line-break:auto;line-height:1.846;text-align:left;text-align:start;text-decoration:none;text-shadow:none;text-transform:none;white-space:normal;word-break:normal;word-spacing:normal;word-wrap:normal;font-size:13px;background-color:#ffffff;-webkit-background-clip:padding-box;background-clip:padding-box;border:1px solid transparent;border-radius:3px;-webkit-box-shadow:0 5px 10px rgba(0,0,0,0.2);box-shadow:0 5px 10px rgba(0,0,0,0.2)}
.paper .popover.top{margin-top:-10px}
.paper .popover.right{margin-left:10px}
.paper .popover.bottom{margin-top:10px}
.paper .popover.left{margin-left:-10px}
.paper .popover-title{margin:0;padding:8px 14px;font-size:13px;background-color:#f7f7f7;border-bottom:1px solid #ebebeb;border-radius:2px 2px 0 0}
.paper .popover-content{padding:9px 14px}
.paper .popover>.arrow, .paper .popover>.arrow:after{position:absolute;display:block;width:0;height:0;border-color:transparent;border-style:solid}
.paper .popover>.arrow{border-width:11px}
.paper .popover>.arrow:after{border-width:10px;content:""}
.paper .popover.top>.arrow{left:50%;margin-left:-11px;border-bottom-width:0;border-top-color:rgba(0,0,0,0);border-top-color:rgba(0,0,0,0.12);bottom:-11px}
.paper .popover.top>.arrow:after{content:" ";bottom:1px;margin-left:-10px;border-bottom-width:0;border-top-color:#ffffff}
.paper .popover.right>.arrow{top:50%;left:-11px;margin-top:-11px;border-left-width:0;border-right-color:rgba(0,0,0,0);border-right-color:rgba(0,0,0,0.12)}
.paper .popover.right>.arrow:after{content:" ";left:1px;bottom:-10px;border-left-width:0;border-right-color:#ffffff}
.paper .popover.bottom>.arrow{left:50%;margin-left:-11px;border-top-width:0;border-bottom-color:rgba(0,0,0,0);border-bottom-color:rgba(0,0,0,0.12);top:-11px}
.paper .popover.bottom>.arrow:after{content:" ";top:1px;margin-left:-10px;border-top-width:0;border-bottom-color:#ffffff}
.paper .popover.left>.arrow{top:50%;right:-11px;margin-top:-11px;border-right-width:0;border-left-color:rgba(0,0,0,0);border-left-color:rgba(0,0,0,0.12)}
.paper .popover.left>.arrow:after{content:" ";right:1px;border-right-width:0;border-left-color:#ffffff;bottom:-10px}
.paper .carousel{position:relative}
.paper .carousel-inner{position:relative;overflow:hidden;width:100%}
.paper .carousel-inner>.item{display:none;position:relative;-webkit-transition:.6s ease-in-out left;-o-transition:.6s ease-in-out left;transition:.6s ease-in-out left}
.paper .carousel-inner>.item>img, .paper .carousel-inner>.item>a>img{line-height:1}
@media all and (transform-3d),(-webkit-transform-3d){
.paper .carousel-inner>.item{-webkit-transition:-webkit-transform .6s ease-in-out;-o-transition:-o-transform .6s ease-in-out;transition:transform .6s ease-in-out;-webkit-backface-visibility:hidden;backface-visibility:hidden;-webkit-perspective:1000px;perspective:1000px}
.paper .carousel-inner>.item.next, .paper .carousel-inner>.item.active.right{-webkit-transform:translate3d(100%, 0, 0);transform:translate3d(100%, 0, 0);left:0}
.paper .carousel-inner>.item.prev, .paper .carousel-inner>.item.active.left{-webkit-transform:translate3d(-100%, 0, 0);transform:translate3d(-100%, 0, 0);left:0}
.paper .carousel-inner>.item.next.left, .paper .carousel-inner>.item.prev.right, .paper .carousel-inner>.item.active{-webkit-transform:translate3d(0, 0, 0);transform:translate3d(0, 0, 0);left:0}
}
.paper .carousel-inner>.active, .paper .carousel-inner>.next, .paper .carousel-inner>.prev{display:block}
.paper .carousel-inner>.active{left:0}
.paper .carousel-inner>.next, .paper .carousel-inner>.prev{position:absolute;top:0;width:100%}
.paper .carousel-inner>.next{left:100%}
.paper .carousel-inner>.prev{left:-100%}
.paper .carousel-inner>.next.left, .paper .carousel-inner>.prev.right{left:0}
.paper .carousel-inner>.active.left{left:-100%}
.paper .carousel-inner>.active.right{left:100%}
.paper .carousel-control{position:absolute;top:0;left:0;bottom:0;width:15%;opacity:0.5;filter:alpha(opacity=50);font-size:20px;color:#ffffff;text-align:center;text-shadow:0 1px 2px rgba(0,0,0,0.6);background-color:rgba(0,0,0,0)}
.paper .carousel-control.left{background-image:-webkit-linear-gradient(left, rgba(0,0,0,0.5) 0, rgba(0,0,0,0.0001) 100%);background-image:-o-linear-gradient(left, rgba(0,0,0,0.5) 0, rgba(0,0,0,0.0001) 100%);background-image:-webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.5)), to(rgba(0,0,0,0.0001)));background-image:linear-gradient(to right, rgba(0,0,0,0.5) 0, rgba(0,0,0,0.0001) 100%);background-repeat:repeat-x;filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1)}
.paper .carousel-control.right{left:auto;right:0;background-image:-webkit-linear-gradient(left, rgba(0,0,0,0.0001) 0, rgba(0,0,0,0.5) 100%);background-image:-o-linear-gradient(left, rgba(0,0,0,0.0001) 0, rgba(0,0,0,0.5) 100%);background-image:-webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.0001)), to(rgba(0,0,0,0.5)));background-image:linear-gradient(to right, rgba(0,0,0,0.0001) 0, rgba(0,0,0,0.5) 100%);background-repeat:repeat-x;filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1)}
.paper .carousel-control:hover, .paper .carousel-control:focus{outline:0;color:#ffffff;text-decoration:none;opacity:0.9;filter:alpha(opacity=90)}
.paper .carousel-control .icon-prev, .paper .carousel-control .icon-next, .paper .carousel-control .glyphicon-chevron-left, .paper .carousel-control .glyphicon-chevron-right{position:absolute;top:50%;margin-top:-10px;z-index:5;display:inline-block}
.paper .carousel-control .icon-prev, .paper .carousel-control .glyphicon-chevron-left{left:50%;margin-left:-10px}
.paper .carousel-control .icon-next, .paper .carousel-control .glyphicon-chevron-right{right:50%;margin-right:-10px}
.paper .carousel-control .icon-prev, .paper .carousel-control .icon-next{width:20px;height:20px;line-height:1;font-family:serif}
.paper .carousel-control .icon-prev:before{content:'\2039'}
.paper .carousel-control .icon-next:before{content:'\203a'}
.paper .carousel-indicators{position:absolute;bottom:10px;left:50%;z-index:15;width:60%;margin-left:-30%;padding-left:0;list-style:none;text-align:center}
.paper .carousel-indicators li{display:inline-block;width:10px;height:10px;margin:1px;text-indent:-999px;border:1px solid #ffffff;border-radius:10px;cursor:pointer;background-color:#000 \9;background-color:rgba(0,0,0,0)}
.paper .carousel-indicators .active{margin:0;width:12px;height:12px;background-color:#ffffff}
.paper .carousel-caption{position:absolute;left:15%;right:15%;bottom:20px;z-index:10;padding-top:20px;padding-bottom:20px;color:#ffffff;text-align:center;text-shadow:0 1px 2px rgba(0,0,0,0.6)}
.paper .carousel-caption .btn{text-shadow:none}
@media screen and (min-width:768px){
.paper .carousel-control .glyphicon-chevron-left, .paper .carousel-control .glyphicon-chevron-right, .paper .carousel-control .icon-prev, .paper .carousel-control .icon-next{width:30px;height:30px;margin-top:-10px;font-size:30px}
.paper .carousel-control .glyphicon-chevron-left, .paper .carousel-control .icon-prev{margin-left:-10px}
.paper .carousel-control .glyphicon-chevron-right, .paper .carousel-control .icon-next{margin-right:-10px}
.paper .carousel-caption{left:20%;right:20%;padding-bottom:30px}
.paper .carousel-indicators{bottom:20px}
}
.paper .clearfix:before, .paper .clearfix:after, .paper .dl-horizontal dd:before, .paper .dl-horizontal dd:after, .paper .container:before, .paper .container:after, .paper .container-fluid:before, .paper .container-fluid:after, .paper .row:before, .paper .row:after, .paper .form-horizontal .form-group:before, .paper .form-horizontal .form-group:after, .paper .btn-toolbar:before, .paper .btn-toolbar:after, .paper .btn-group-vertical>.btn-group:before, .paper .btn-group-vertical>.btn-group:after, .paper .nav:before, .paper .nav:after, .paper .navbar:before, .paper .navbar:after, .paper .navbar-header:before, .paper .navbar-header:after, .paper .navbar-collapse:before, .paper .navbar-collapse:after, .paper .pager:before, .paper .pager:after, .paper .panel-body:before, .paper .panel-body:after, .paper .modal-header:before, .paper .modal-header:after, .paper .modal-footer:before, .paper .modal-footer:after{content:" ";display:table}
.paper .clearfix:after, .paper .dl-horizontal dd:after, .paper .container:after, .paper .container-fluid:after, .paper .row:after, .paper .form-horizontal .form-group:after, .paper .btn-toolbar:after, .paper .btn-group-vertical>.btn-group:after, .paper .nav:after, .paper .navbar:after, .paper .navbar-header:after, .paper .navbar-collapse:after, .paper .pager:after, .paper .panel-body:after, .paper .modal-header:after, .paper .modal-footer:after{clear:both}
.paper .center-block{display:block;margin-left:auto;margin-right:auto}
.paper .pull-right{float:right !important}
.paper .pull-left{float:left !important}
.paper .hide{display:none !important}
.paper .show{display:block !important}
.paper .invisible{visibility:hidden}
.paper .text-hide{font:0/0 a;color:transparent;text-shadow:none;background-color:transparent;border:0}
.paper .hidden{display:none !important}
.paper .affix{position:fixed}
@-ms-viewport{width:device-width}
.paper .visible-xs, .paper .visible-sm, .paper .visible-md, .paper .visible-lg{display:none !important}
.paper .visible-xs-block, .paper .visible-xs-inline, .paper .visible-xs-inline-block, .paper .visible-sm-block, .paper .visible-sm-inline, .paper .visible-sm-inline-block, .paper .visible-md-block, .paper .visible-md-inline, .paper .visible-md-inline-block, .paper .visible-lg-block, .paper .visible-lg-inline, .paper .visible-lg-inline-block{display:none !important}
@media (max-width:767px){
.paper .visible-xs{display:block !important}
.paper table.visible-xs{display:table !important}
.paper tr.visible-xs{display:table-row !important}
.paper th.visible-xs, .paper td.visible-xs{display:table-cell !important}
}
@media (max-width:767px){
.paper .visible-xs-block{display:block !important}
}
@media (max-width:767px){
.paper .visible-xs-inline{display:inline !important}
}
@media (max-width:767px){
.paper .visible-xs-inline-block{display:inline-block !important}
}
@media (min-width:768px) and (max-width:991px){
.paper .visible-sm{display:block !important}
.paper table.visible-sm{display:table !important}
.paper tr.visible-sm{display:table-row !important}
.paper th.visible-sm, .paper td.visible-sm{display:table-cell !important}
}
@media (min-width:768px) and (max-width:991px){
.paper .visible-sm-block{display:block !important}
}
@media (min-width:768px) and (max-width:991px){
.paper .visible-sm-inline{display:inline !important}
}
@media (min-width:768px) and (max-width:991px){
.paper .visible-sm-inline-block{display:inline-block !important}
}
@media (min-width:992px) and (max-width:1199px){
.paper .visible-md{display:block !important}
.paper table.visible-md{display:table !important}
.paper tr.visible-md{display:table-row !important}
.paper th.visible-md, .paper td.visible-md{display:table-cell !important}
}
@media (min-width:992px) and (max-width:1199px){
.paper .visible-md-block{display:block !important}
}
@media (min-width:992px) and (max-width:1199px){
.paper .visible-md-inline{display:inline !important}
}
@media (min-width:992px) and (max-width:1199px){
.paper .visible-md-inline-block{display:inline-block !important}
}
@media (min-width:1200px){
.paper .visible-lg{display:block !important}
.paper table.visible-lg{display:table !important}
.paper tr.visible-lg{display:table-row !important}
.paper th.visible-lg, .paper td.visible-lg{display:table-cell !important}
}
@media (min-width:1200px){
.paper .visible-lg-block{display:block !important}
}
@media (min-width:1200px){
.paper .visible-lg-inline{display:inline !important}
}
@media (min-width:1200px){
.paper .visible-lg-inline-block{display:inline-block !important}
}
@media (max-width:767px){
.paper .hidden-xs{display:none !important}
}
@media (min-width:768px) and (max-width:991px){
.paper .hidden-sm{display:none !important}
}
@media (min-width:992px) and (max-width:1199px){
.paper .hidden-md{display:none !important}
}
@media (min-width:1200px){
.paper .hidden-lg{display:none !important}
}
.paper .visible-print{display:none !important}
@media print{
.paper .visible-print{display:block !important}
.paper table.visible-print{display:table !important}
.paper tr.visible-print{display:table-row !important}
.paper th.visible-print, .paper td.visible-print{display:table-cell !important}
}
.paper .visible-print-block{display:none !important}
@media print{
.paper .visible-print-block{display:block !important}
}
.paper .visible-print-inline{display:none !important}
@media print{
.paper .visible-print-inline{display:inline !important}
}
.paper .visible-print-inline-block{display:none !important}
@media print{
.paper .visible-print-inline-block{display:inline-block !important}
}
@media print{
.paper .hidden-print{display:none !important}
}
.paper .navbar{border:none;-webkit-box-shadow:0 1px 2px rgba(0,0,0,0.3);box-shadow:0 1px 2px rgba(0,0,0,0.3)}
.paper .navbar-brand{font-size:24px}
.paper .navbar-inverse .navbar-form input[type=text], .paper .navbar-inverse .navbar-form input[type=password]{color:#fff;-webkit-box-shadow:inset 0 -1px 0 #b2dbfb;box-shadow:inset 0 -1px 0 #b2dbfb}
.paper .navbar-inverse .navbar-form input[type=text]::-moz-placeholder, .paper .navbar-inverse .navbar-form input[type=password]::-moz-placeholder{color:#b2dbfb;opacity:1}
.paper .navbar-inverse .navbar-form input[type=text]:-ms-input-placeholder, .paper .navbar-inverse .navbar-form input[type=password]:-ms-input-placeholder{color:#b2dbfb}
.paper .navbar-inverse .navbar-form input[type=text]::-webkit-input-placeholder, .paper .navbar-inverse .navbar-form input[type=password]::-webkit-input-placeholder{color:#b2dbfb}
.paper .navbar-inverse .navbar-form input[type=text]:focus, .paper .navbar-inverse .navbar-form input[type=password]:focus{-webkit-box-shadow:inset 0 -2px 0 #fff;box-shadow:inset 0 -2px 0 #fff}
.paper .btn-default{position:relative}
.paper .btn-default:focus{background-color:#ffffff}
.paper .btn-default:hover, .paper .btn-default:active:hover{background-color:#f0f0f0}
.paper .btn-default:active{-webkit-box-shadow:2px 2px 4px rgba(0,0,0,0.4);box-shadow:2px 2px 4px rgba(0,0,0,0.4)}
.paper .btn-default:after{content:"";display:block;position:absolute;width:100%;height:100%;top:0;left:0;background-image:-webkit-radial-gradient(circle, #444 10%, transparent 10.01%);background-image:-o-radial-gradient(circle, #444 10%, transparent 10.01%);background-image:radial-gradient(circle, #444 10%, transparent 10.01%);background-repeat:no-repeat;-webkit-background-size:1000% 1000%;background-size:1000% 1000%;background-position:50%;opacity:0;pointer-events:none;-webkit-transition:background .5s, opacity 1s;-o-transition:background .5s, opacity 1s;transition:background .5s, opacity 1s}
.paper .btn-default:active:after{-webkit-background-size:0% 0%;background-size:0% 0%;opacity:.2;-webkit-transition:0s;-o-transition:0s;transition:0s}
.paper .btn-primary{position:relative}
.paper .btn-primary:focus{background-color:#2196f3}
.paper .btn-primary:hover, .paper .btn-primary:active:hover{background-color:#0d87e9}
.paper .btn-primary:active{-webkit-box-shadow:2px 2px 4px rgba(0,0,0,0.4);box-shadow:2px 2px 4px rgba(0,0,0,0.4)}
.paper .btn-primary:after{content:"";display:block;position:absolute;width:100%;height:100%;top:0;left:0;background-image:-webkit-radial-gradient(circle, #fff 10%, transparent 10.01%);background-image:-o-radial-gradient(circle, #fff 10%, transparent 10.01%);background-image:radial-gradient(circle, #fff 10%, transparent 10.01%);background-repeat:no-repeat;-webkit-background-size:1000% 1000%;background-size:1000% 1000%;background-position:50%;opacity:0;pointer-events:none;-webkit-transition:background .5s, opacity 1s;-o-transition:background .5s, opacity 1s;transition:background .5s, opacity 1s}
.paper .btn-primary:active:after{-webkit-background-size:0% 0%;background-size:0% 0%;opacity:.2;-webkit-transition:0s;-o-transition:0s;transition:0s}
.paper .btn-success{position:relative}
.paper .btn-success:focus{background-color:#4caf50}
.paper .btn-success:hover, .paper .btn-success:active:hover{background-color:#439a46}
.paper .btn-success:active{-webkit-box-shadow:2px 2px 4px rgba(0,0,0,0.4);box-shadow:2px 2px 4px rgba(0,0,0,0.4)}
.paper .btn-success:after{content:"";display:block;position:absolute;width:100%;height:100%;top:0;left:0;background-image:-webkit-radial-gradient(circle, #fff 10%, transparent 10.01%);background-image:-o-radial-gradient(circle, #fff 10%, transparent 10.01%);background-image:radial-gradient(circle, #fff 10%, transparent 10.01%);background-repeat:no-repeat;-webkit-background-size:1000% 1000%;background-size:1000% 1000%;background-position:50%;opacity:0;pointer-events:none;-webkit-transition:background .5s, opacity 1s;-o-transition:background .5s, opacity 1s;transition:background .5s, opacity 1s}
.paper .btn-success:active:after{-webkit-background-size:0% 0%;background-size:0% 0%;opacity:.2;-webkit-transition:0s;-o-transition:0s;transition:0s}
.paper .btn-info{position:relative}
.paper .btn-info:focus{background-color:#9c27b0}
.paper .btn-info:hover, .paper .btn-info:active:hover{background-color:#862197}
.paper .btn-info:active{-webkit-box-shadow:2px 2px 4px rgba(0,0,0,0.4);box-shadow:2px 2px 4px rgba(0,0,0,0.4)}
.paper .btn-info:after{content:"";display:block;position:absolute;width:100%;height:100%;top:0;left:0;background-image:-webkit-radial-gradient(circle, #fff 10%, transparent 10.01%);background-image:-o-radial-gradient(circle, #fff 10%, transparent 10.01%);background-image:radial-gradient(circle, #fff 10%, transparent 10.01%);background-repeat:no-repeat;-webkit-background-size:1000% 1000%;background-size:1000% 1000%;background-position:50%;opacity:0;pointer-events:none;-webkit-transition:background .5s, opacity 1s;-o-transition:background .5s, opacity 1s;transition:background .5s, opacity 1s}
.paper .btn-info:active:after{-webkit-background-size:0% 0%;background-size:0% 0%;opacity:.2;-webkit-transition:0s;-o-transition:0s;transition:0s}
.paper .btn-warning{position:relative}
.paper .btn-warning:focus{background-color:#ff9800}
.paper .btn-warning:hover, .paper .btn-warning:active:hover{background-color:#e08600}
.paper .btn-warning:active{-webkit-box-shadow:2px 2px 4px rgba(0,0,0,0.4);box-shadow:2px 2px 4px rgba(0,0,0,0.4)}
.paper .btn-warning:after{content:"";display:block;position:absolute;width:100%;height:100%;top:0;left:0;background-image:-webkit-radial-gradient(circle, #fff 10%, transparent 10.01%);background-image:-o-radial-gradient(circle, #fff 10%, transparent 10.01%);background-image:radial-gradient(circle, #fff 10%, transparent 10.01%);background-repeat:no-repeat;-webkit-background-size:1000% 1000%;background-size:1000% 1000%;background-position:50%;opacity:0;pointer-events:none;-webkit-transition:background .5s, opacity 1s;-o-transition:background .5s, opacity 1s;transition:background .5s, opacity 1s}
.paper .btn-warning:active:after{-webkit-background-size:0% 0%;background-size:0% 0%;opacity:.2;-webkit-transition:0s;-o-transition:0s;transition:0s}
.paper .btn-danger{position:relative}
.paper .btn-danger:focus{background-color:#e51c23}
.paper .btn-danger:hover, .paper .btn-danger:active:hover{background-color:#cb171e}
.paper .btn-danger:active{-webkit-box-shadow:2px 2px 4px rgba(0,0,0,0.4);box-shadow:2px 2px 4px rgba(0,0,0,0.4)}
.paper .btn-danger:after{content:"";display:block;position:absolute;width:100%;height:100%;top:0;left:0;background-image:-webkit-radial-gradient(circle, #fff 10%, transparent 10.01%);background-image:-o-radial-gradient(circle, #fff 10%, transparent 10.01%);background-image:radial-gradient(circle, #fff 10%, transparent 10.01%);background-repeat:no-repeat;-webkit-background-size:1000% 1000%;background-size:1000% 1000%;background-position:50%;opacity:0;pointer-events:none;-webkit-transition:background .5s, opacity 1s;-o-transition:background .5s, opacity 1s;transition:background .5s, opacity 1s}
.paper .btn-danger:active:after{-webkit-background-size:0% 0%;background-size:0% 0%;opacity:.2;-webkit-transition:0s;-o-transition:0s;transition:0s}
.paper .btn-link{position:relative}
.paper .btn-link:focus{background-color:#ffffff}
.paper .btn-link:hover, .paper .btn-link:active:hover{background-color:#f0f0f0}
.paper .btn-link:active{-webkit-box-shadow:2px 2px 4px rgba(0,0,0,0.4);box-shadow:2px 2px 4px rgba(0,0,0,0.4)}
.paper .btn-link:after{content:"";display:block;position:absolute;width:100%;height:100%;top:0;left:0;background-image:-webkit-radial-gradient(circle, #444 10%, transparent 10.01%);background-image:-o-radial-gradient(circle, #444 10%, transparent 10.01%);background-image:radial-gradient(circle, #444 10%, transparent 10.01%);background-repeat:no-repeat;-webkit-background-size:1000% 1000%;background-size:1000% 1000%;background-position:50%;opacity:0;pointer-events:none;-webkit-transition:background .5s, opacity 1s;-o-transition:background .5s, opacity 1s;transition:background .5s, opacity 1s}
.paper .btn-link:active:after{-webkit-background-size:0% 0%;background-size:0% 0%;opacity:.2;-webkit-transition:0s;-o-transition:0s;transition:0s}
.paper .btn{text-transform:uppercase;border:none;-webkit-box-shadow:1px 1px 4px rgba(0,0,0,0.4);box-shadow:1px 1px 4px rgba(0,0,0,0.4);-webkit-transition:all 0.4s;-o-transition:all 0.4s;transition:all 0.4s}
.paper .btn-link{border-radius:3px;-webkit-box-shadow:none;box-shadow:none;color:#444444}
.paper .btn-link:hover, .paper .btn-link:focus{-webkit-box-shadow:none;box-shadow:none;color:#444444;text-decoration:none}
.paper .btn-link .disabled:hover, .paper .btn-link[disabled]:hover, .paper fieldset[disabled] .btn-link:hover, .paper .btn-link .disabled:active:hover, .paper .btn-link[disabled]:active:hover, .paper fieldset[disabled] .btn-link:active:hover{background-color:#fff;color:#444444}
.paper .btn-default.disabled, .paper .btn-default[disabled], .paper fieldset[disabled] .btn-default{background-color:rgba(0,0,0,0.1);color:rgba(0,0,0,0.4);opacity:1}
.paper .btn-default.disabled:hover, .paper .btn-default[disabled]:hover, .paper fieldset[disabled] .btn-default:hover, .paper .btn-default.disabled:focus, .paper .btn-default[disabled]:focus, .paper fieldset[disabled] .btn-default:focus{background-color:rgba(0,0,0,0.1)}
.paper .btn-group .btn+.btn, .paper .btn-group .btn+.btn-group, .paper .btn-group .btn-group+.btn, .paper .btn-group .btn-group+.btn-group{margin-left:0}
.paper .btn-group-vertical>.btn+.btn, .paper .btn-group-vertical>.btn+.btn-group, .paper .btn-group-vertical>.btn-group+.btn, .paper .btn-group-vertical>.btn-group+.btn-group{margin-top:0}
.paper body{-webkit-font-smoothing:antialiased;letter-spacing:.1px}
.paper p{margin:0 0 1em}
.paper input, .paper button{-webkit-font-smoothing:antialiased;letter-spacing:.1px}
.paper a{-webkit-transition:all 0.2s;-o-transition:all 0.2s;transition:all 0.2s}
.paper .table-hover>tbody>tr, .paper .table-hover>tbody>tr>th, .paper .table-hover>tbody>tr>td{-webkit-transition:all 0.2s;-o-transition:all 0.2s;transition:all 0.2s}
.paper label{font-weight:normal}
.paper textarea, .paper textarea.form-control, .paper input.form-control, .paper input[type=text], .paper input[type=password], .paper input[type=email], .paper input[type=number], .paper [type=text].form-control, .paper [type=password].form-control, .paper [type=email].form-control, .paper [type=tel].form-control, .paper [contenteditable].form-control{padding:0;border:none;border-radius:0;-webkit-appearance:none;-webkit-box-shadow:inset 0 -1px 0 #ddd;box-shadow:inset 0 -1px 0 #ddd;font-size:16px}
.paper textarea:focus, .paper textarea.form-control:focus, .paper input.form-control:focus, .paper input[type=text]:focus, .paper input[type=password]:focus, .paper input[type=email]:focus, .paper input[type=number]:focus, .paper [type=text].form-control:focus, .paper [type=password].form-control:focus, .paper [type=email].form-control:focus, .paper [type=tel].form-control:focus, .paper [contenteditable].form-control:focus{-webkit-box-shadow:inset 0 -2px 0 #2196f3;box-shadow:inset 0 -2px 0 #2196f3}
.paper textarea[disabled], .paper textarea.form-control[disabled], .paper input.form-control[disabled], .paper input[type=text][disabled], .paper input[type=password][disabled], .paper input[type=email][disabled], .paper input[type=number][disabled], .paper [type=text].form-control[disabled], .paper [type=password].form-control[disabled], .paper [type=email].form-control[disabled], .paper [type=tel].form-control[disabled], .paper [contenteditable].form-control[disabled], .paper textarea[readonly], .paper textarea.form-control[readonly], .paper input.form-control[readonly], .paper input[type=text][readonly], .paper input[type=password][readonly], .paper input[type=email][readonly], .paper input[type=number][readonly], .paper [type=text].form-control[readonly], .paper [type=password].form-control[readonly], .paper [type=email].form-control[readonly], .paper [type=tel].form-control[readonly], .paper [contenteditable].form-control[readonly]{-webkit-box-shadow:none;box-shadow:none;border-bottom:1px dotted #ddd}
.paper textarea.input-sm, .paper textarea.form-control.input-sm, .paper input.form-control.input-sm, .paper input[type=text].input-sm, .paper input[type=password].input-sm, .paper input[type=email].input-sm, .paper input[type=number].input-sm, .paper [type=text].form-control.input-sm, .paper [type=password].form-control.input-sm, .paper [type=email].form-control.input-sm, .paper [type=tel].form-control.input-sm, .paper [contenteditable].form-control.input-sm{font-size:12px}
.paper textarea.input-lg, .paper textarea.form-control.input-lg, .paper input.form-control.input-lg, .paper input[type=text].input-lg, .paper input[type=password].input-lg, .paper input[type=email].input-lg, .paper input[type=number].input-lg, .paper [type=text].form-control.input-lg, .paper [type=password].form-control.input-lg, .paper [type=email].form-control.input-lg, .paper [type=tel].form-control.input-lg, .paper [contenteditable].form-control.input-lg{font-size:17px}
.paper select, .paper select.form-control{border:0;border-radius:0;-webkit-appearance:none;-moz-appearance:none;appearance:none;padding-left:0;padding-right:0\9;background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEVmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmaP/QSjAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);-webkit-background-size:13px 13px;background-size:13px;background-repeat:no-repeat;background-position:right center;-webkit-box-shadow:inset 0 -1px 0 #ddd;box-shadow:inset 0 -1px 0 #ddd;font-size:16px;line-height:1.5}
.paper select::-ms-expand, .paper select.form-control::-ms-expand{display:none}
.paper select.input-sm, .paper select.form-control.input-sm{font-size:12px}
.paper select.input-lg, .paper select.form-control.input-lg{font-size:17px}
.paper select:focus, .paper select.form-control:focus{-webkit-box-shadow:inset 0 -2px 0 #2196f3;box-shadow:inset 0 -2px 0 #2196f3;background-image:url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEUhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISF8S9ewAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=)}
.paper select[disabled], .paper select.form-control[disabled], .paper select[readonly], .paper select.form-control[readonly]{-webkit-box-shadow:none;box-shadow:none;border-bottom:1px dotted #ddd}
.paper select[disabled], .paper select.form-control[disabled], .paper select[readonly], .paper select.form-control[readonly], .paper select[disabled] option, .paper select.form-control[disabled] option, .paper select[readonly] option, .paper select.form-control[readonly] option{color:#bbbbbb}
.paper select[multiple], .paper select.form-control[multiple]{background:none}
.paper .radio label, .paper .radio-inline label, .paper .checkbox label, .paper .checkbox-inline label{padding-left:25px}
.paper .radio input[type="radio"], .paper .radio-inline input[type="radio"], .paper .checkbox input[type="radio"], .paper .checkbox-inline input[type="radio"], .paper .radio input[type="checkbox"], .paper .radio-inline input[type="checkbox"], .paper .checkbox input[type="checkbox"], .paper .checkbox-inline input[type="checkbox"]{margin-left:-25px}
.paper input[type="radio"], .paper .radio input[type="radio"], .paper .radio-inline input[type="radio"]{position:relative;margin-top:6px;margin-right:4px;vertical-align:top;border:none;background-color:transparent;-webkit-appearance:none;appearance:none;cursor:pointer}
.paper input[type="radio"]:focus, .paper .radio input[type="radio"]:focus, .paper .radio-inline input[type="radio"]:focus{outline:none}
.paper input[type="radio"]:before, .paper .radio input[type="radio"]:before, .paper .radio-inline input[type="radio"]:before, .paper input[type="radio"]:after, .paper .radio input[type="radio"]:after, .paper .radio-inline input[type="radio"]:after{content:"";display:block;width:18px;height:18px;border-radius:50%;-webkit-transition:240ms;-o-transition:240ms;transition:240ms}
.paper input[type="radio"]:before, .paper .radio input[type="radio"]:before, .paper .radio-inline input[type="radio"]:before{position:absolute;left:0;top:-3px;background-color:#2196f3;-webkit-transform:scale(0);-ms-transform:scale(0);-o-transform:scale(0);transform:scale(0)}
.paper input[type="radio"]:after, .paper .radio input[type="radio"]:after, .paper .radio-inline input[type="radio"]:after{position:relative;top:-3px;border:2px solid #666666}
.paper input[type="radio"]:checked:before, .paper .radio input[type="radio"]:checked:before, .paper .radio-inline input[type="radio"]:checked:before{-webkit-transform:scale(.5);-ms-transform:scale(.5);-o-transform:scale(.5);transform:scale(.5)}
.paper input[type="radio"]:disabled:checked:before, .paper .radio input[type="radio"]:disabled:checked:before, .paper .radio-inline input[type="radio"]:disabled:checked:before{background-color:#bbbbbb}
.paper input[type="radio"]:checked:after, .paper .radio input[type="radio"]:checked:after, .paper .radio-inline input[type="radio"]:checked:after{border-color:#2196f3}
.paper input[type="radio"]:disabled:after, .paper .radio input[type="radio"]:disabled:after, .paper .radio-inline input[type="radio"]:disabled:after, .paper input[type="radio"]:disabled:checked:after, .paper .radio input[type="radio"]:disabled:checked:after, .paper .radio-inline input[type="radio"]:disabled:checked:after{border-color:#bbbbbb}
.paper input[type="checkbox"], .paper .checkbox input[type="checkbox"], .paper .checkbox-inline input[type="checkbox"]{position:relative;border:none;margin-bottom:-4px;-webkit-appearance:none;appearance:none;cursor:pointer}
.paper input[type="checkbox"]:focus, .paper .checkbox input[type="checkbox"]:focus, .paper .checkbox-inline input[type="checkbox"]:focus{outline:none}
.paper input[type="checkbox"]:focus:after, .paper .checkbox input[type="checkbox"]:focus:after, .paper .checkbox-inline input[type="checkbox"]:focus:after{border-color:#2196f3}
.paper input[type="checkbox"]:after, .paper .checkbox input[type="checkbox"]:after, .paper .checkbox-inline input[type="checkbox"]:after{content:"";display:block;width:18px;height:18px;margin-top:-2px;margin-right:5px;border:2px solid #666666;border-radius:2px;-webkit-transition:240ms;-o-transition:240ms;transition:240ms}
.paper input[type="checkbox"]:checked:before, .paper .checkbox input[type="checkbox"]:checked:before, .paper .checkbox-inline input[type="checkbox"]:checked:before{content:"";position:absolute;top:0;left:6px;display:table;width:6px;height:12px;border:2px solid #fff;border-top-width:0;border-left-width:0;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);-o-transform:rotate(45deg);transform:rotate(45deg)}
.paper input[type="checkbox"]:checked:after, .paper .checkbox input[type="checkbox"]:checked:after, .paper .checkbox-inline input[type="checkbox"]:checked:after{background-color:#2196f3;border-color:#2196f3}
.paper input[type="checkbox"]:disabled:after, .paper .checkbox input[type="checkbox"]:disabled:after, .paper .checkbox-inline input[type="checkbox"]:disabled:after{border-color:#bbbbbb}
.paper input[type="checkbox"]:disabled:checked:after, .paper .checkbox input[type="checkbox"]:disabled:checked:after, .paper .checkbox-inline input[type="checkbox"]:disabled:checked:after{background-color:#bbbbbb;border-color:transparent}
.paper .has-warning input:not([type=checkbox]), .paper .has-warning .form-control, .paper .has-warning input.form-control[readonly], .paper .has-warning input[type=text][readonly], .paper .has-warning [type=text].form-control[readonly], .paper .has-warning input:not([type=checkbox]):focus, .paper .has-warning .form-control:focus{border-bottom:none;-webkit-box-shadow:inset 0 -2px 0 #ff9800;box-shadow:inset 0 -2px 0 #ff9800}
.paper .has-error input:not([type=checkbox]), .paper .has-error .form-control, .paper .has-error input.form-control[readonly], .paper .has-error input[type=text][readonly], .paper .has-error [type=text].form-control[readonly], .paper .has-error input:not([type=checkbox]):focus, .paper .has-error .form-control:focus{border-bottom:none;-webkit-box-shadow:inset 0 -2px 0 #e51c23;box-shadow:inset 0 -2px 0 #e51c23}
.paper .has-success input:not([type=checkbox]), .paper .has-success .form-control, .paper .has-success input.form-control[readonly], .paper .has-success input[type=text][readonly], .paper .has-success [type=text].form-control[readonly], .paper .has-success input:not([type=checkbox]):focus, .paper .has-success .form-control:focus{border-bottom:none;-webkit-box-shadow:inset 0 -2px 0 #4caf50;box-shadow:inset 0 -2px 0 #4caf50}
.paper .has-warning .input-group-addon, .paper .has-error .input-group-addon, .paper .has-success .input-group-addon{color:#666666;border-color:transparent;background-color:transparent}
.paper .form-group-lg select, .paper .form-group-lg select.form-control{line-height:1.5}
.paper .nav-tabs>li>a, .paper .nav-tabs>li>a:focus{margin-right:0;background-color:transparent;border:none;color:#666666;-webkit-box-shadow:inset 0 -1px 0 #ddd;box-shadow:inset 0 -1px 0 #ddd;-webkit-transition:all 0.2s;-o-transition:all 0.2s;transition:all 0.2s}
.paper .nav-tabs>li>a:hover, .paper .nav-tabs>li>a:focus:hover{background-color:transparent;-webkit-box-shadow:inset 0 -2px 0 #2196f3;box-shadow:inset 0 -2px 0 #2196f3;color:#2196f3}
.paper .nav-tabs>li.active>a, .paper .nav-tabs>li.active>a:focus{border:none;-webkit-box-shadow:inset 0 -2px 0 #2196f3;box-shadow:inset 0 -2px 0 #2196f3;color:#2196f3}
.paper .nav-tabs>li.active>a:hover, .paper .nav-tabs>li.active>a:focus:hover{border:none;color:#2196f3}
.paper .nav-tabs>li.disabled>a{-webkit-box-shadow:inset 0 -1px 0 #ddd;box-shadow:inset 0 -1px 0 #ddd}
.paper .nav-tabs.nav-justified>li>a, .paper .nav-tabs.nav-justified>li>a:hover, .paper .nav-tabs.nav-justified>li>a:focus, .paper .nav-tabs.nav-justified>.active>a, .paper .nav-tabs.nav-justified>.active>a:hover, .paper .nav-tabs.nav-justified>.active>a:focus{border:none}
.paper .nav-tabs .dropdown-menu{margin-top:0}
.paper .dropdown-menu{margin-top:0;border:none;-webkit-box-shadow:0 1px 4px rgba(0,0,0,0.3);box-shadow:0 1px 4px rgba(0,0,0,0.3)}
.paper .alert{border:none}
.paper .badge{padding:4px 6px 4px}
.paper .progress{position:relative;z-index:1;height:6px;border-radius:0;-webkit-box-shadow:none;box-shadow:none}
.paper .progress-bar{-webkit-box-shadow:none;box-shadow:none}
.paper .progress-bar:last-child{border-radius:0 3px 3px 0}
.paper .progress-bar:last-child:before{display:block;content:"";position:absolute;width:100%;height:100%;left:0;right:0;z-index:-1;background-color:#cae6fc}
.paper .progress-bar-success:last-child.progress-bar:before{background-color:#c7e7c8}
.paper .progress-bar-info:last-child.progress-bar:before{background-color:#edc9f3}
.paper .progress-bar-warning:last-child.progress-bar:before{background-color:#ffe0b3}
.paper .progress-bar-danger:last-child.progress-bar:before{background-color:#f28e92}
.paper .close{font-size:34px;font-weight:300;line-height:24px;opacity:0.6;-webkit-transition:all 0.2s;-o-transition:all 0.2s;transition:all 0.2s}
.paper .close:hover{opacity:1}
.paper .list-group-item{padding:15px}
.paper .list-group-item-text{color:#bbbbbb}
.paper .well{border-radius:0;-webkit-box-shadow:none;box-shadow:none}
.paper .panel{border:none;border-radius:2px;-webkit-box-shadow:0 1px 4px rgba(0,0,0,0.3);box-shadow:0 1px 4px rgba(0,0,0,0.3)}
.paper .panel-heading{border-bottom:none}
.paper .panel-footer{border-top:none}
.paper .popover{border:none;-webkit-box-shadow:0 1px 4px rgba(0,0,0,0.3);box-shadow:0 1px 4px rgba(0,0,0,0.3)}
.paper .carousel-caption h1, .paper .carousel-caption h2, .paper .carousel-caption h3, .paper .carousel-caption h4, .paper .carousel-caption h5, .paper .carousel-caption h6{color:inherit}
