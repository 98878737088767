/* Generated by Glyphter (http://www.glyphter.com) on  Tue Feb 15 2022*/
@font-face {
    font-family: '7tv2 fantasy';
    src: url('../fonts/7tv2-fantasy.eot');
    src: url('../fonts/7tv2-fantasy.eot?#iefix') format('embedded-opentype'),
    url('../fonts/7tv2-fantasy.woff') format('woff'),
    url('../fonts/7tv2-fantasy.ttf') format('truetype'),
    url('../fonts/7tv2-fantasy.svg#7tv2-fantasy') format('svg');
    font-weight: normal;
    font-style: normal;
}

.font-7tv2-fantasy {
    font-family: '7tv2 fantasy'; font-style: normal;
}

[class*='icon-']:before {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

/* A */.icon-genre-fantasy-epic:before { font-family: '7tv2 fantasy';content: '\0041';}
/* B */.icon-genre-fantasy-folktale:before { font-family: '7tv2 fantasy';content: '\0042';}
/* C */.icon-genre-fantasy-historical:before { font-family: '7tv2 fantasy';content: '\0043';}
/* E */.icon-genre-fantasy-mythic:before { font-family: '7tv2 fantasy';content: '\0045';}
/* F */.icon-genre-fantasy-pen-n-paper:before { font-family: '7tv2 fantasy';content: '\0046';}
/* I */.icon-genre-fantasy-sword-n-sorcery:before { font-family: '7tv2 fantasy';content: '\0049'; }

/* D */.icon-melee:before { font-family: '7tv2 fantasy';content: '\0044';}
/* G */.icon-presence:before { font-family: '7tv2 fantasy';content: '\0047'; }
/* H */.icon-range:before { font-family: '7tv2 fantasy';content: '\0048'; }