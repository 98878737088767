/* Generated by Glyphter (http://www.glyphter.com) on  Tue Feb 15 2022*/
@font-face {
    font-family: '7tv2 core';
    src: url('../fonts/7tv2-core.eot');
    src: url('../fonts/7tv2-core.eot?#iefix') format('embedded-opentype'),
    url('../fonts/7tv2-core.woff') format('woff'),
    url('../fonts/7tv2-core.ttf') format('truetype'),
    url('../fonts/7tv2-core.svg#7tv2-core') format('svg');
    font-weight: normal;
    font-style: normal;
}

.font-7tv2-core {
    font-family: '7tv2 core'; font-style: normal;
}

[class*='icon-']:before {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

/* A */.icon-genre-core-civilian:before { font-family: '7tv2 core';content: '\0041';}
/* B */.icon-genre-core-crime:before { font-family: '7tv2 core';content: '\0042';}
/* D */.icon-genre-core-law:before { font-family: '7tv2 core';content: '\0044';}
/* E */.icon-genre-core-military:before { font-family: '7tv2 core';content: '\0045';}
/* F */.icon-genre-core-science:before { font-family: '7tv2 core';content: '\0046';}
/* G */.icon-genre-core-secret:before { font-family: '7tv2 core';content: '\0047';}
/* J */.icon-genre-core-unknown:before { font-family: '7tv2 core';content: '\004a';}

/* C */.icon-fist_icon:before { font-family: '7tv2 core';content: '\0043';}
/* H */.icon-star_icon:before { font-family: '7tv2 core';content: '\0048';}
/* I */.icon-target_icon:before { font-family: '7tv2 core';content: '\0049'; }
/* K */.icon-drive_icon:before { font-family: '7tv2 core';content: '\004b';}