@font-face {
	font-family: 'DINCond';
	src: url('DINCond-Regular.eot');
	src: url('DINCond-Regular.eot?#iefix') format('embedded-opentype'),
	url('DINCond-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'DINCond';
	src: url('DINCond-Bold.eot');
	src: url('DINCond-Bold.eot?#iefix') format('embedded-opentype'),
	url('DINCond-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}