.pulp.card  {
  color: #58595b !important;
  strong {
    color: #58595b !important;
  }
}

.pulp.card.vehicle,
.pulp.card.model,
.pulp.card.unit {
  section {
    header {
      color: #c07c31;
    }
    .icon-star_icon:before {
      color: #c07c31;
    }
    p em {
      color: #c07c31;
    }
  }
}

.pulp.card.front .background {
  background: url(images/pulp/2e_pulp_model_front.jpg);
  background-size: cover;
}
.pulp.card.back .background {
  background: url(images/pulp/2e_pulp_model_back.jpg);
  background-size: cover;
}


.pulp.card.model .stats > div > div {
    background: #3f251e
}
.pulp.card.model .stats > div > div:last-child {
  flex-grow: 0;
  width: 30%;
  color: #fff;
  font-weight: bold;
  font-size: 110%;
  background-color: #c07c31;
}
.pulp.card.model .stats var {
  position: relative;
  top: 10%;
}
.pulp.card.model .sfxribbon dd  {
  color: #c07c31;
}
.pulp.card.model .stats:after {
  content: "";
  position: absolute;
  left: 0%;
  top: 0;
  height: 100%;
  width: 100%;
  display: block;
  background: url(images/pulp/2e_pulp_statbox.png) no-repeat;
}
.pulp.card.model .stats.right:after {
  background-position: -280%;
}
.pulp.card.model .stats.left:after {
  background-position: 400%;
}

.pulp.card .title {
  line-height: 125%;
  display: block;
  width: 100%;
  text-align: center;
  font-size: 2em;
  text-transform: uppercase;
  position: relative;
}

.pulp.card .title strong {
  position: absolute; color: white !important;
  width: 100%;
  top: 0.1em;
  display: block;
  transform: rotate(-1deg);
}
.pulp.card .title span {
  position: absolute;
  font-size: 50%;
  right: 0;
  color: white;
  width: 20%;
  line-height: 1em;
  margin-top: 0.3em;
}
