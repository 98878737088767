/* Generated by Glyphter (http://www.glyphter.com) on  Tue Mar 19 2024*/
@font-face {
    font-family: '7tv2 eighties';
    src: url('../fonts/7tv2-eighties.eot');
    src: url('../fonts/7tv2-eighties.eot?#iefix') format('embedded-opentype'),
    url('../fonts/7tv2-eighties.woff') format('woff'),
    url('../fonts/7tv2-eighties.ttf') format('truetype'),
    url('../fonts/7tv2-eighties.svg#7tv2-eighties') format('svg');
    font-weight: normal;
    font-style: normal;
}

.font-7tv2-eighties {
    font-family: '7tv2 eighties'; font-style: normal;
}

[class*='icon-']:before {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}
/* A */.icon-genre-eighties-action:before{font-family: '7tv2 eighties'; content:'\0041';}
/* B */.icon-genre-eighties-adventure:before{font-family: '7tv2 eighties'; content:'\0042';}
/* C */.icon-genre-eighties-fantasy:before{font-family: '7tv2 eighties'; content:'\0043';}
/* D */.icon-genre-eighties-historical:before{font-family: '7tv2 eighties'; content:'\0044';}
/* E */.icon-genre-eighties-horror:before{font-family: '7tv2 eighties'; content:'\0045';}
/* F */.icon-genre-eighties-scifi:before{font-family: '7tv2 eighties'; content:'\0046';}

/* G */.icon-presence:before { font-family: '7tv2 fantasy';content: '\0047'; }