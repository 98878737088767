

.characterEditor { height: 100%; overflow: auto}
.characterEditor .field-description {
    font-size: smaller; color: #666; margin-left: 1em; 
}

.characterEditor .array-item { display:flex; background: rgba(0,0,0, 0.05); margin: 1%; padding: 1%}
.characterEditor .array-item textarea {background-color: rgba(255,255,255,0.5) !important; padding: 1%; border-top: 1px solid rgba(0,0,0, 0.1); margin-top: 1%; }
.characterEditor .pictureWidget img {width:300px}
/* .characterEditor .pictureWidget img {width:200px; float:right;}
.characterEditor .pictureWidget {display:block}
.characterEditor .pictureWidget:after {content: "";
    display: table;
    clear: both;} */

.characterEditor select {border-top: 1px solid #eee !important} 

.characterEditor fieldset .form-group {display:flex}

.characterEditor fieldset .form-group .field-integer >* {flex-grow: 1}
.characterEditor fieldset .form-group .field-integer p,
.characterEditor fieldset .form-group .field-integer input  {width: 30%}

.profileSelector .panel-body {padding:0 !important}
.profileSelector .listing { overflow: auto; height:400px; }
.profileSelector .listing .miniCard {margin: 5px}
.profileSelector .listing .miniCard+.miniCard {border-top: 1px solid #eee}
.profileSelector .listing .miniCard dd {font-size: smaller}
.profileSelector .listing .miniCard.selected { background: #C1DFEA}

.editorToolBar .dropdown ,
.editorToolBar .dropdown > button { width:100% !important}

.ReactCrop {background-color: transparent; background-image: url(images/trans_10.png); border: 10px solid White;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; min-height: 200px;
    transform: rotate(1deg); margin: 1em}


.iconCheckboxes {width: 100%}
.iconCheckboxes strong {text-transform: capitalize}
.iconCheckboxes i {font-size: 2em}